import React, { useEffect, useRef, useState } from 'react';
import searchData from '../data/data.json'; // Import your JSON data
import { Link } from 'react-router-dom'; // For navigation
import '../style/search.css'
import SearchPopup from './SearchPopup';
import searchIcon from '../image/HeadingImg/Search.png'
import { useNavigate } from 'react-router-dom';
import { FiSearch, FiX } from 'react-icons/fi'

const SearchComponent = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [isInputBoxOpen, setIsInputBoxOpen] = useState(false);

  const handleSearchClick = () => {
    setIsInputBoxOpen(true);
  };
  const handleCloseClick = () => {
    setIsInputBoxOpen(false);
    setShowPopup(false);
    setQuery('');
    setResults([]);
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setQuery(searchQuery);

    if (!searchQuery) {
      setResults([]);
      setShowPopup(false);
      return;
    }

    const filteredResults = Object.keys(searchData)
      .filter(key => {
        const data = searchData[key];
        const description = data.description ? data.description.toLowerCase() : '';
        const title = data.title ? data.title.toLowerCase() : '';
        const content = data.content ? data.content.toLowerCase() : '';
        return description.includes(searchQuery) || title.includes(searchQuery);
      })
      .map(key => ({
        path: searchData[key].path,
        title: searchData[key].title,
        description: searchData[key].description,
        content: searchData[key].content,
      }));

    setResults(filteredResults);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  // --------------------------------
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navigate = useNavigate();
  const searchInputRef = useRef(null);

  const handleSearchToggle = () => {
    setIsSearchOpen(true);

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleClose = () => {
    setIsSearchOpen(false);
    setQuery('');
    setResults([]);
  };


  const handleLinkClick = (path) => {
    navigate(path);
    handleClose();
  };

  useEffect(() => {
    if (isSearchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchOpen]);


  return (
    // <div className={`SearchBox p-2 col-md-6 d-flex align-items-center  ${isInputBoxOpen ? 'open' : ''}`}>
    //   <input type="text" value={query} onChange={handleSearch} placeholder="Search..." />
    //   <span className='search' onClick={handleSearchClick}>
    //     <img className='searchIcon' src={searchIcon} alt="" />
    //   </span>
    //   <i className="fa-solid fa-xmark close-icon" onClick={handleCloseClick}></i>
    //   {showPopup && (
    //     <SearchPopup results={results} query={query} onClose={handleClosePopup} />
    //   )}
    // </div>

    <div className="position-relative ">
      {/* Search Icon */}
      <div className="SearchPointer" role="button" aria-expanded="true" onClick={handleSearchToggle} aria-label="Search" tabindex="0">
        <FiSearch size={20} className='SearchContentIcon' />
      </div>

      {/* Full-Screen Search Input */}
      {isSearchOpen && (
        <div
          className="position-fixed top-0 start-0 fullSearchBox bg-light d-flex align-items-center justify-content-center"
          style={{ zIndex: 1050 }}>
          <div className="container SearchContainer position-relative">
            <FiSearch size={20} className='InsideSearchIcon' />
            <input type="text" ref={searchInputRef} className='w-100 px-5 smSearchBox' value={query} onChange={handleSearch} placeholder="Search..." />
            <div className="position-absolute top-0 end-0 text-end CloseBtn" onClick={handleClose} aria-label="Close" >
              <FiX size={24} />
            </div>

            {/* Search Results */}
            {isSearchOpen && (
              <SearchPopup results={results} query={query} onClose={handleClose} onLinkClick={handleLinkClick}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
