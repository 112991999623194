import React from 'react'
import TrustedOrganization from './TrustedOrganization'
const Banner = React.lazy(() => import('./Banner'));

const CookiePolicy = ({ adata }) => {
  const CookiesData = adata;
  return (
    <>
      <Banner />
      <TrustedOrganization />
      <div className="card cookiesPolicySection">
        <div className="card-body">
          <h3 style={{ color: "#f37021" }} className="card-title border-bottom border-dark mb-5 pb-3 text-center fw-bold"  dangerouslySetInnerHTML={{ __html: CookiesData[0].Heading }}></h3>
          <h5 className="card-subtitle mb-2 border-bottom border-dark mb-3 fw-bold d-inline border-2">{CookiesData[0].subHeading}</h5>
          {
            CookiesData[0].Data.map((data, index) => (
              <div className="cookies_content" key={index}>
                {
                  data.Header !== "" ? <h5 className='mb-2 fw-bold' dangerouslySetInnerHTML={{ __html: data.Header }}></h5> : null
                }
                {
                  data.desc.map((data1, index1) => (
                    <p className="mb-2" dangerouslySetInnerHTML={{ __html: data1.Desc }} key={index1}></p>
                  ))
                }
                {
                  data.ul && (
                    <ul>
                      {
                        data.ul.map((item, index) => (
                          <li className="mb-1" style={{ listStyleType: "circle" }} key={index}>{item.list}</li>
                        ))
                      }
                    </ul>
                  )
                }
                {
                  data.ListDesctiption && (
                    <>
                      {
                        data.ListDesctiption.map((item, index) => (
                          <p key={index} className="mb-2" dangerouslySetInnerHTML={{ __html: item.DescHeading }}></p>
                        ))
                      }
                      <ul>
                        {
                          data.ListDesctiption.map((item, index) => (
                            <li key={index} className="mb-1" style={{ listStyleType: "circle" }}><strong>{item.StrongHeading}</strong> {item.Desc}</li>
                          ))
                        }
                      </ul>
                    </>
                  )
                }
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}

export default CookiePolicy