import React, { useEffect, useState } from 'react'
import TrustedOrganization from '../TrustedOrganization'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { GoPlus } from "react-icons/go";
import { AccordionButton } from 'react-bootstrap';
import { GoHorizontalRule } from "react-icons/go";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { GetStarted } from '../FooterSection/GetStarted';
import FooterBrands from '../FooterBrands';
import { Helmet } from 'react-helmet';
const Banner = React.lazy(() => import('../Banner'));


export const EC_COE = ({ adata }) => {
    const ECCOE = adata

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };

    useEffect(() => {
        Aos.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 1269);
        };

        window.addEventListener('resize', updateScreenSize);

        updateScreenSize();

        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Enterprise Cloud | Phenom&#x1D49;Cloud</title>
                <meta
                    name="description"
                    content="Phenomecloud offers expert cloud solutions across Azure®, AWS®, and Google Cloud®, including migration, DevOps, security, cost optimization, managed services, and 24/7 support."
                />
                <meta
                    name="keywords"
                    content="Cloud Migration | Cloud optimization |Cloud Security | DevOps & Automation | Azure®, AWS®, Google® Cloud Support"
                />
                <meta
                    name="robots"
                    content="index, follow"
                />
                <meta
                    property="og:title"
                    content=">Enterprise Cloud | Phenom&#x1D49;Cloud"
                />
                <meta
                    property="og:description"
                    content="Phenomecloud offers expert cloud solutions across Azure®, AWS®, and Google Cloud®, including migration, DevOps, security, cost optimization, managed services, and 24/7 support."
                />
                <meta
                    property="og:keywords"
                    content="Cloud Migration | Cloud optimization |Cloud Security | DevOps & Automation | Azure®, AWS®, Google® Cloud Support"
                />
                {/* <meta
                    property="og:title"
                    content="Modernize Your Business with Full-Stack Cloud Services on Azure, AWS, and Google Cloud "
                />
                <meta
                    property="og:description"
                    content="Cloud Migration & DevOps Solutions for Seamless Transition."
                />
                <meta
                    property="og:keywords"
                    content="Cloud Security & Cost Optimization for Enhanced Performance, 24/7 Managed Services & Support for Cloud Infrastructure"
                /> */}
            </Helmet>
            <Banner />
            <TrustedOrganization />
            <div className="EcCoe">
                {
                    ECCOE.map((item, index) => {
                        const { className, ContentData } = item;
                        return (
                            className === "FlexContentBody" && (
                                <div className={`${className} p-5 py-2 border-padding-remove`} key={index}>
                                    {
                                        item.LeadTagLine && (
                                            <h3 className='text-dark text-center fw-bold pt-2' dangerouslySetInnerHTML={{ __html: item.LeadTagLine }}></h3>
                                        )
                                    }
                                    {ContentData && (
                                        ContentData.map((item, index) => {
                                            const { className, Grid, } = item;
                                            return (
                                                <div className={className} key={index}>
                                                    {
                                                        Grid.map((item, index) => {
                                                            const { className, TextData } = item;
                                                            return (
                                                                <div className={className} key={index}>

                                                                    {
                                                                        TextData.map((item, index) => {
                                                                            const { className, BtnName, LeadContent, Content, iconImg, Img, BtnUrl } = item;
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {
                                                                                        Content ? (
                                                                                            <div className="d-flex align-items-center mb-3" key={index} data-aos="linear">
                                                                                                {
                                                                                                    iconImg && (
                                                                                                        <div className="ImgSection text-black">
                                                                                                            <img className='small-icon me-3' src={require(`../../image/EC/EC COE/${iconImg}`)} alt="Enterprise Cloud Solutions | Phenom&#x1D49;Cloud" />
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                                <div>
                                                                                                    {LeadContent && LeadContent.map((item) => {
                                                                                                        const { Name } = item;
                                                                                                        return (
                                                                                                            <>
                                                                                                                <p dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                                                            </>
                                                                                                        )
                                                                                                    })


                                                                                                    }
                                                                                                </div>

                                                                                                {BtnUrl && (
                                                                                                    <Link to={BtnUrl} className={className}>{BtnName}</Link>
                                                                                                )}
                                                                                            </div>
                                                                                        ) : (
                                                                                            Img && (
                                                                                                <div className="d-flex justify-content-center text-black" >
                                                                                                    <img className='img-size' src={require(`../../image/EC/EC COE/${Img}`)} alt="Enterprise Cloud Solutions | Phenom&#x1D49;Cloud" />
                                                                                                </div>)
                                                                                        )
                                                                                    }

                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }))
                                    }
                                </div>
                            )
                        )
                    })
                }
                {
                    ECCOE.map((item, index) => {
                        const { id, Header, Desc, cta_link, CTA } = item;
                        return (
                            id === 4 &&
                            <div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section white_bg py-4`}>
                                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="Cloud_sloution_cta_header text-center fw-bold ">{Header}</h1>
                                    <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">{Desc}</h3>
                                    {
                                        cta_link &&
                                        <Link to={cta_link} className="cta_btn text-decoration-none">
                                            {CTA}
                                        </Link>

                                    }
                                </div>
                            </div>
                        )
                    })

                }
                {
                    ECCOE.map((item, index) => {
                        const { className, HeadingData } = item;
                        return (
                            className === "OurServices" && (
                                <div className={`${className}`} key={index}>
                                    {
                                        HeadingData && (
                                            HeadingData.map((item, index) => {
                                                const { className, Grid, CardSection } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="Heading-Content justify-content-center text-center align-items-center row px-4 padding_remove">
                                                            {
                                                                Grid.map((item, index) => {
                                                                    const { GridName, className, Name } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <p className={className} dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            CardSection && (
                                                                CardSection.map((item, index) => {
                                                                    const { className, CardData } = item;
                                                                    return (
                                                                        <div className={className} key={index}>
                                                                            <div className="card-section-content row px-5 justify-content-center text-center padding_remove">
                                                                                {
                                                                                    CardData.map((item, index) => {
                                                                                        const { GridName, className, ImgName, Heading, Name, UrlName, Url, popUp } = item;
                                                                                        return (
                                                                                            <div className={GridName} key={index} data-aos={`${isSmallScreen ? 'fade-up' : popUp}`}>
                                                                                                <Link to={Url} className='text-decoration-none text-black'>
                                                                                                    <div className={className}>
                                                                                                        <div className="cardImg text-start d-flex justify-content-between align-items-center text-black">
                                                                                                            {
                                                                                                                Heading && (
                                                                                                                    <p className='fw-bolder card-heading-size'>{Heading}</p>
                                                                                                                )
                                                                                                            }
                                                                                                            {ImgName && (
                                                                                                                <img className='icon-size icon-box' src={require(`../../image/EC/EC COE/${ImgName}`)} alt="Enterprise Cloud Solutions | Phenom&#x1D49;Cloud" />
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className="card-body px-1 d-flex flex-column text-start justify-content-center">
                                                                                                            <p className="Description text-start m-0" dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                                                        </div>
                                                                                                        {
                                                                                                            Url && (
                                                                                                                <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                                                                                    <Link to={Url} className='text-start text-decoration-none UrlText px-2' href="">{UrlName}</Link>
                                                                                                                    <Link className='btn card-btn-color' to={Url}><i className="fa-solid fa-arrow-right px-2 "></i></Link>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                </Link>

                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            )

                                                        }
                                                    </div>
                                                )
                                            })
                                        )
                                    }

                                </div>
                            )
                        )
                    })
                }
                {
                    ECCOE.map((item, index) => {
                        const { id, Header, Desc, cta_link, CTA } = item;
                        return (
                            id === 5 &&
                            <div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section white_bg py-4`}>
                                <div className="col-md-12 d-flex flex-column  justify-content-center align-items-center">
                                    <div className="downloadBookContainer col-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column    align-items-start">
                                        <h1 className="Cloud_sloution_cta_header   fw-bold " dangerouslySetInnerHTML={{ __html: Header }}></h1>
                                        <h3 className="Cloud_sloution_cta_Subheader   mb-3">{Desc}</h3>
                                    </div>
                                    <div className=" col-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center">
                                        {
                                            cta_link &&
                                            <Link to={cta_link} className="cta_btn eccoe_cta text-decoration-none">
                                                {CTA}
                                            </Link>

                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })

                }
                {
                    ECCOE.map((item, index) => {
                        const { className, ContentData } = item;
                        return (
                            className === "FlexContent" && (
                                <div className={className} key={index}>
                                    {ContentData && (
                                        ContentData.map((item, index) => {
                                            const { containerClass, className, Grid, bottomContent } = item;
                                            return (
                                                <div className={className} key={index}>
                                                    <div className={`${containerClass ? containerClass : ''}`}>

                                                        {
                                                            Grid.map((item, index) => {
                                                                const { className, TextData } = item;
                                                                return (
                                                                    <div className={`${className}`} key={index}>
                                                                        {
                                                                            TextData.map((item, index) => {
                                                                                const { HeadinClass, className, BtnName, DropDown, IdName, Name, iconImg, Img, BtnUrl, mainGridClass } = item;
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        {
                                                                                            HeadinClass ? (
                                                                                                <div className={`${HeadinClass ? HeadinClass : ''}`} id={IdName} >
                                                                                                    {
                                                                                                        iconImg && (
                                                                                                            <div className="ImgSection text-black">
                                                                                                                <img className='small-icon me-2' src={require(`../../image/ERP/ERP COE/${iconImg}`)} alt="Enterprise Cloud Solutions | Phenom&#x1D49;Cloud" />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    {Name && (
                                                                                                        <p className={className} dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                                                    )
                                                                                                    }
                                                                                                    {BtnUrl && (
                                                                                                        <Link to={BtnUrl} className={className}>{BtnName}</Link>
                                                                                                    )}

                                                                                                </div>
                                                                                            ) : (
                                                                                                < >
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            mainGridClass ? (
                                                                                                <div className={`${mainGridClass ? mainGridClass : ''}`} id={IdName} >

                                                                                                    <p className={className}>{Name}</p>

                                                                                                    {
                                                                                                        DropDown && (
                                                                                                            <Accordion className='mb-5 mt-5 Ec-fix-Height' data-aos="zoom-in">
                                                                                                                {DropDown.map((item, index) => {
                                                                                                                    const { Name, DescriptionData } = item;
                                                                                                                    return (
                                                                                                                        <Accordion.Item eventKey={index.toString()} className='m-2 border-0' key={index} >
                                                                                                                            <AccordionButton className='Dropdown-Bg-Color'
                                                                                                                                onClick={() => handleToggle(index.toString())}
                                                                                                                                aria-expanded={expandedItem === index.toString()}
                                                                                                                            >
                                                                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                                                                    <span className='fw-bolder sm-font-size'>{Name}</span>
                                                                                                                                    {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                                                                                </div>
                                                                                                                            </AccordionButton>
                                                                                                                            {DescriptionData.map((descItem, descIndex) => {
                                                                                                                                const { Heading, Name } = descItem;
                                                                                                                                return (
                                                                                                                                    <Accordion.Body key={descIndex} className='py-1'>
                                                                                                                                        <p className='fw-bolder sm-font-size'>{Heading}</p>
                                                                                                                                        <p className='sm-font-size' dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                                                                                    </Accordion.Body>
                                                                                                                                );
                                                                                                                            })}
                                                                                                                        </Accordion.Item>
                                                                                                                    );
                                                                                                                })}
                                                                                                            </Accordion>)
                                                                                                    }

                                                                                                    {Img && (
                                                                                                        <div className=" d-flex justify-content-center text-black" data-aos="zoom-in">
                                                                                                            <img className='img-size float-end' src={require(`../../image/EC/EC COE/${Img}`)} alt="Enterprise Cloud Solutions | Phenom&#x1D49;Cloud" />
                                                                                                        </div>)}

                                                                                                </div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )
                                                                                        }

                                                                                    </React.Fragment>
                                                                                );
                                                                            }
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <p className="text-center " dangerouslySetInnerHTML={{ __html: bottomContent }}></p>

                                                </div>
                                            )
                                        }))
                                    }

                                </div>
                            )
                        )
                    })
                }


            </div>
            <FooterBrands />
            <GetStarted />
        </>
    )
}
