import { Helmet } from "react-helmet"
import '../style/Industry.css'
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

const IndustryDetails = ({ adata }) => {
    var industriesData = adata;

      const [isSubmitted, setSubmitted] = useState(false);
      const [formKey, setFormKey] = useState(0); // State to force re-render 
    
      const loadForm = () => {
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
    
        script.addEventListener('load', () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              region: "na1",
              portalId: "1751195",
              formId: "a74585cb-3938-4132-b8ec-da98d81e2747",
              target: `.myFormDemo-${formKey}`, // Use the formKey to differentiate
              onFormSubmit: () => { setSubmitted(true); },
            });
          }
        });
      };
    
      useEffect(() => {
        loadForm();
      }, [formKey]);

      const CustomPopup = () => {
        return (
          <div className="custom-popup-backdrop">
            <div className="custom-popup">
              <div className='d-flex flex-column justify-content-evenly'>
                <h2 className='text-center fw-bold'>Thank you for reaching out!</h2>
                <p className='text-center text-dark'>
                  A Phenom<sup>e</sup>Cloud specialist will get in touch with you soon to discuss how we can help maximize the potential of your HR technology. While you wait, feel free to explore how Phenom<sup>e</sup>Cloud can enhance your HR experience.
                </p>
                <p className='text-center text-dark'>
                  We’re excited to work with you!
                </p>
                <p className='text-center d-flex justify-content-center mt-4'>
                  <button
                    className='btn btn-outline-purple backButton mx-1'
                    onClick={() => {
                      setSubmitted(false);
                      setFormKey(prevKey => prevKey + 1); // Increment key to force re-render
                    }}>
                    <span><IoArrowBack /></span>Back to page
                  </button>
                </p>
              </div>
            </div>
          </div>
        );
      };
    return (
        <>
        {
            industriesData[0].Helmet.map((item,index) =>(
                <Helmet>
                <title>{item.title}</title>
                <meta name="description" content={item.meta} />

                <meta
                    property="og:title"
                    content={item.title}
                />
                <meta
                    property="og:description"
                    content={item.meta}
                />
                 <meta
                    name="keywords"
                    content={item.Keywords}
                />
                {
                    item.H1.map((item,index) =>(
                        <meta name="h1" content={item.text} /> 
                    )) 
                }
                {
                    item.H2.map((item,index) =>(
                        <meta name="h2" content={item.text} />
                    )) 
                } 
            </Helmet>

            ))
        } 
          <div className="Industry_Details">
              {
                industriesData.map((item,index) =>{
                    const {id, BannerImage, BannerDesc, BannerHeader,  Grid, Name, Header, Image, Descriptions, SubHeader} = item;
                    return (
                      <>
                        {id === 1 && (
                          <div
                            className="IndustryDetails_Banner_Section"
                            key={index}
                          >
                            <div className="banner_image">
                              <img
                                src={require(`../image/Industry/${BannerImage}`)}
                                alt="banner_image"
                              />
                            </div>
                            <div className="content_section">
                              <h2 className="fw-bold text-center">
                                {BannerHeader}
                              </h2>
                              <p className="text-center">{BannerDesc}</p>
                            </div>
                          </div>
                        )}
                        {id === 2 && (
                          <div className="industryDetails_grid_Section">
                            <div className="row justify-content-center">
                              {Grid.map((item, index) => (
                                <div
                                  key={index}
                                  className={`grid_item col-md-12 ${
                                    index === 3 ? "grid_last_card" : ""
                                  } ${index % 2 === 0 ? "light_blue_bg" : ""}`}
                                >
                                  {index === 0 || index === 3 ? (
                                    <>
                                      <div className=" d-flex justify-content-center ">
                                        <div className="col-md-8 ">
                                          <p className="pt-5 pb-3 fw-bold" style={{color:'rgb(239, 87, 19)'}}>
                                            {item.Header}
                                          </p>
                                          {/* <p>{item.SubHeader}</p> */}
                                          <h3 className="fw-bold ">
                                            {item.SubHeader}
                                          </h3>
                                          <div className="description_content">
                                            {item.Descriptions.map(
                                              (item, index) => {
                                                return (
                                                  <>
                                                    {item.text && (
                                                      <p
                                                        className="mb-3"
                                                        key={index}
                                                      >
                                                        {item.text}
                                                      </p>
                                                    )}
                                                    {item.ul && (
                                                      <ul key={index}>
                                                        {item.ul.map(
                                                          (item, index) => (
                                                            <li>{item.li}</li>
                                                          )
                                                        )}
                                                      </ul>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                          {item.ButtomContent && (
                                            <p className="Bottom_desc">
                                              {item.ButtomContent}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className={`${item.ClassName} industryDetails_grid_imgae_container row align-items-center`}
                                      >
                                        <div className="col-md-6 col-sm-12 grid_item_image_container p-4 text-center">
                                          {item.Image && (
                                            <img
                                              src={require(`../image/Industry/${item.Image}`)}
                                              alt="image"
                                            />
                                          )}
                                        </div>
                                        <div className="col-md-6 col-sm-12 grid_item_Content_container">
                                          <p className="pb-3 fw-bold" style={{color:'rgb(239, 87, 19)'}}>
                                            {item.Header}
                                          </p>
                                          {/* <p>{item.SubHeader}</p> */}
                                          <h3 className="fw-bold ">
                                            {item.SubHeader}
                                          </h3>
                                          <div className="description_content">
                                            {item.Descriptions.map(
                                              (item, index) => {
                                                return (
                                                  <>
                                                    {item.text && (
                                                      <p
                                                        className="mb-3"
                                                        key={index}
                                                      >
                                                        {item.text}
                                                      </p>
                                                    )}
                                                    {item.ul && (
                                                      <ul key={index}>
                                                        {item.ul.map(
                                                          (item, index) => (
                                                            <li>{item.li}</li>
                                                          )
                                                        )}
                                                      </ul>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                          {item.ButtomContent && (
                                            <p className="Bottom_desc">
                                              {item.ButtomContent}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {id === 3 && (
                          <div
                            className={`SuccessStoriesMainSection p-5 ${
                              id === 3 ? "light_blue_bg" : ""
                            }`}
                          >
                            <h3 className="h3 text-center fw-bold pb-3">
                              {item.Heading}
                            </h3>
                            <div className="SuccessStoriesSection industriesSuccessStories container">
                              <div className="row justify-content-center">
                                {item.Grid.map((item, index) => {
                                  const {
                                    GridName,
                                    dataaos,
                                    dataduration,
                                    datadelay,
                                    status,
                                    Desc,
                                    Img,
                                    Url,
                                  } = item;
                                  return (
                                    <div className={GridName} key={index}>
                                      <Link
                                        to={Url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="card h-100 text-decoration-none"
                                      >
                                        <div className="CardImgSection">
                                          <img
                                            src={require(`../image/OurClient/${Img}`)}
                                            className="OurClientCaseStudyImg"
                                            alt="OurClient Image"
                                          />
                                        </div>
                                        <div
                                          className="card-body d-flex flex-column justify-content-between"
                                          style={{ padding: "6% 6% 8% 6%" }}
                                        >
                                          <p className="py-3 Description-Fontsize">
                                            {Desc}
                                          </p>
                                          <div className="SuccessStoriesStatus">
                                            <i class="animation"></i>
                                            {status}
                                            <i class="animation"></i>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                        {id === 4 && (
                          <div id="demoform" style={{ width: "100%" }}>
                            <div className="d-flex justify-content-center mx-auto p-0 w-100">
                              <div className="demo d-flex justify-content-center text-dark form_section col-12 col-md-9 p-3">
                                <div className="mt-4 demo form_section_content w-100 pt-3">
                                  <div
                                    className="text-center"
                                    style={{ marginBottom: "2%" }}
                                  >
                                    <h3>{item.demoSection.header}</h3>
                                    <p>{item.demoSection.subHeader}</p>
                                  </div>
                                  <div
                                    className={`myFormDemo myFormDemo-${formKey}`}
                                  ></div>
                                  {isSubmitted && <CustomPopup />}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {id === 5 && (
                          <div
                            className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section HCM_cta_section light_blue_bg py-5`}
                          >
                            <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                              <h1
                                className="Cloud_sloution_cta_header text-center fw-bold"
                                dangerouslySetInnerHTML={{ __html: item.title }}
                              ></h1>
                              <h5 className="Cloud_sloution_cta_Subheader HCM_cta_Subheader text-center mb-3">
                                {item.Desc}
                              </h5>
                              {item.cta_link && (
                                <Link
                                  to={item.cta_link}
                                  className="DownloadBtn"
                                >
                                  {item.CTA}
                                </Link>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    );
                })
              }
               {/* <div className="IndustryDetails_Banner_Section"  >
                                        <div className="banner_image">
                                            <img src={require(`../image/Industry/Automative.png`)} alt="banner_image" />
                                        </div>
                                        <div className="content_section">
                                            <h2 className="fw-bold">Third Sector & Not-For-Profit</h2>
                                            <p>Supporting third sector, not-for-profit and government organisations in building leadership strength</p>
                                        </div>
                                     </div> */}
          </div>
        </>
    )
}
export default IndustryDetails