import React, { useEffect } from "react";
import Banner from "./Banner";
import '../style/RoiCalculator.css'
import { Helmet } from "react-helmet";

export const RoiCalculator = () => {
    useEffect(() => {
        const iframe = document.getElementById('iframedata');
        if (iframe) {
            iframe.addEventListener('load', () => {
                window.scrollTo(0, 0);
                iframe.setAttribute('scrolling', 'yes');
            });
        }
    }, []); 

    return (

        <div className="roi_calculator">
            <Helmet>
                <title>Enterprise Technology Solutions ROI Calculator | Phenom&#x1D49;Cloud</title>
                <meta
                    name="description"
                    content="Calculate the ROI of your HR initiatives. Input your data into our value calculator to uncover ways to boost top-line and bottom-line results. "
                />
                <meta
                    name="keywords"
                    content=""
                />
                <meta
                    name="robots"
                    content="index, follow"
                />
                <meta
                    property="og:title"
                    content="Enterprise Technology Solutions ROI Calculator | Phenom&#x1D49;Cloud"
                />
                <meta
                    property="og:description"
                    content="Calculate the ROI of your HR initiatives. Input your data into our value calculator to uncover ways to boost top-line and bottom-line results."
                />
                <meta
                    property="og:keywords"
                    content=""
                />
            </Helmet>
            <Banner />
            <div className="container-fluid row mainROIConteiner" >
                <iframe title="ROI Calculator" className="col-12"
                    id="iframedata"
                    // src="http://52.163.49.37:189/"   //QA
                    src="https://roi.phenomecloud.com/"  //Prod
                    frameborder="0"
                    scrolling="no"
                    style={{ width: '100vw', height: '120vh', paddingTop: '20px', overflow: 'scroll',  border: 'none' }}
                >
                </iframe>
            </div>

        </div>
    )
}