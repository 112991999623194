import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import FooterBrands from './FooterBrands';

const DigitalAlliances = ({ adata }) => {
    const ClientData = adata;

    const videoRef = useRef(null);
    const [isPaused, setIsPaused] = useState(true);
    const statsRef = useRef(null);
    useEffect(() => {
        Aos.init({
            duration: 1000,
            once: true,
        });
    }, []);

    useEffect(() => {
        const videoElement = videoRef.current;

        const handlePlay = () => setIsPaused(false);
        const handlePause = () => setIsPaused(true);

        if (videoElement) {
            videoElement.addEventListener("play", handlePlay);
            videoElement.addEventListener("pause", handlePause);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener("play", handlePlay);
                videoElement.removeEventListener("pause", handlePause);
            }
        };
    }, []);


    const handlePlayPause = () => {
        const video = videoRef.current;
        if (!video) return;

        if (isPaused) {
            requestAnimationFrame(() => video.play());
        } else {
            requestAnimationFrame(() => video.pause());
        }
    };

    return (
        <>
            {
                ClientData.map((item, index) => {
                    const { MetaTitle, MetaDescription } = item;
                    return (
                        <Helmet key={index}>
                            <title>Phenom&#x1D49;Cloud {`${MetaTitle}`}</title>
                            <meta name="description" content={MetaDescription} />
                            <meta
                                property="og:description"
                                content={MetaDescription}
                            />
                        </Helmet>
                    )
                })
            }

            <div className="OurClientMainSection pt-5">
                <div className="secondPadding">
                    {
                        ClientData.map((item, index) => {
                            const { id, HeadingName, FirstBtnName, FirstBtnUrl, SecondBtnName, SecondBtnUrl, VideoUrl, Grid, accordianData, title, Desc, CTA, cta_link, Testimonials } = item;
                            return (
                                <React.Fragment key={index}>
                                    {
                                        id === 1 && (
                                            <div className='OurClientVideoBanner'>
                                                <div className="px-5 row justify-content-center align-items-center MainBannerPadding">
                                                    <div className="col-md-5 d-flex position-relative MainHeadingSectionContent"
                                                        data-aos="fade-right" data-aos-duration="1000">
                                                        <div className="HeadingSeaction">
                                                            <div className='HeadingBorder'>
                                                                <h2 className='fw-bolder MainHeading'>{HeadingName}</h2>
                                                            </div>
                                                            <h5 className="Heading mb-3 Description-Fontsize mt-3" dangerouslySetInnerHTML={{ __html: Desc }}></h5>
                                                            <div className="d-flex justify-content-between HeadingBtnSection">
                                                                {
                                                                    FirstBtnUrl && (
                                                                        <Link to={FirstBtnUrl} className='DownloadBtn my-2 text-decoration-none'>
                                                                            <span>{FirstBtnName}</span>
                                                                        </Link>
                                                                    )
                                                                }
                                                                {
                                                                    SecondBtnUrl && (
                                                                        <Link to={SecondBtnUrl} className='GetInTouchBtn my-2 text-decoration-none text-center'>
                                                                            <span>{SecondBtnName}</span>
                                                                        </Link>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 p-0 position-relative">
                                                        <svg id="edge" data-name="edge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.1 669.22">
                                                            <defs>
                                                            </defs>
                                                            <path className="edge-1" d="M0,669.22c32.72-85.31,53-203.78,53-334.72h-.1C52.9,203.64,32.66,85.28,0,0v669.22Z"></path>
                                                            <path className="edge-2" d="M0,0v669h10.04c34.85-81.61,56.86-201.22,56.86-334.5S44.89,81.61,10.04,0H0Z" ></path>
                                                            <path className="edge-2" d="M13.1,0H0v669h13.1c41.8-75.12,68.99-196.95,68.99-334.5h0C82.1,196.95,54.91,75.12,13.1,0Z" ></path>
                                                        </svg>
                                                        <video className='VideoMainContent'
                                                            src={require(`../image/OurClient/${VideoUrl}`)}
                                                            controls
                                                            playsinline="playsinline" preload="none"
                                                            ref={videoRef}
                                                            tabIndex="-1"
                                                            role="application"
                                                            loop
                                                            muted
                                                            autoPlay
                                                        />
                                                        {isPaused && (
                                                            <div className="play-button-container" onClick={handlePlayPause}>
                                                                <button className="play-button">▶</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        id === 6 && (
                                            <div className="SuccessStoriesMainSection p-5">
                                                <div className="SuccessStoriesSection container">
                                                    <div className="row justify-content-center">
                                                        {
                                                            Grid.map((item, index) => {
                                                                const { GridName, dataaos, dataduration, datadelay, status, Desc, Img, Url } = item;
                                                                return (
                                                                    <div className={GridName} data-aos={dataaos} data-aos-duration={dataduration} data-aos-delay={datadelay} key={index}>
                                                                        <Link to={Url} target="_blank" rel="noopener noreferrer" className="card h-100 text-decoration-none">
                                                                            <div className="CardImgSection" >
                                                                                <img src={require(`../image/OurClient/${Img}`)} className='OurClientCaseStudyImg' alt="OurClient Image" />
                                                                            </div>
                                                                            <div className="card-body" data-aos="fade-down" data-aos-duration={dataduration} data-aos-delay={datadelay}>
                                                                                <div className='SuccessStoriesStatus'>
                                                                                    <i class="animation"></i>
                                                                                    {status}
                                                                                    <i class="animation"></i>
                                                                                </div>
                                                                                <p className='py-4 Description-Fontsize'>{Desc}</p>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {id === 7 && (
                                        <>
                                            <div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section HCM_cta_section light_blue_bg py-5`}>
                                                <div className="col-md-9 d-flex flex-column justify-content-center align-items-center">
                                                    {
                                                        title.map((item, index) => {
                                                            return (
                                                                <h1 key={index} className="Cloud_sloution_cta_header text-center fw-bold" dangerouslySetInnerHTML={{ __html: item.Heading }}></h1>
                                                            )
                                                        })
                                                    }
                                                    <h5 className="Cloud_sloution_cta_Subheader HCM_cta_Subheader text-center mb-3">
                                                        {Desc}
                                                    </h5>
                                                    {cta_link && (
                                                        <Link to={cta_link} className="DownloadBtn">
                                                            {CTA}
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                            < FooterBrands />
                                        </>
                                    )}






                                </React.Fragment>
                            )
                        })
                    }

                </div>

            </div>
        </>
    )
}
export default DigitalAlliances;