import { useContext } from "react";
import { LanguageContext } from "../src/LanguageContext"; // Ensure the path is correct

export const useFilteredData = (jsonData) => {
    const { language } = useContext(LanguageContext);

    if (!language) {
        console.warn("Language is not defined yet!");
        return []; // Return empty data if language is not available
    }

    // Ensure jsonData is an array
    if (!Array.isArray(jsonData)) {
        console.error("Invalid jsonData passed to useFilteredData");
        return [];
    }

    // Find and return the filtered data based on the selected language
    const filteredData = jsonData.find((item) => item.Language === language)?.Data || [];
    return filteredData;
};
