import React, { useState } from 'react'
import TechnologyAdvisoryServicesData from '../../data/EB/TechnologyAdvisoryServices.json'
import { Helmet } from 'react-helmet';
import TrustedOrganization from '../TrustedOrganization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Accordion, AccordionButton } from 'react-bootstrap'
import { GoHorizontalRule, GoPlus } from 'react-icons/go'
import { GetStarted } from '../FooterSection/GetStarted';
const Banner = React.lazy(() => import('../Banner'));

const TechnologyAdvisoryServices = () => {


    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };

    return (
        <>
            <Helmet>
                <title>Phenom eCloud Enterprise Technology Advisory Services</title>
                <meta
                    name="description"
                    content="Discover expert guidance and tools to make informed decisions about what technological tools your business needs. See how to choose the right technology, build a high-performing team, and avoid most mistakes."
                />
            </Helmet>
            <Banner />
            <TrustedOrganization />

            {TechnologyAdvisoryServicesData.map((item, index) => {
                const { id, title, desc, bannerImage, accordians, tabledata, card } = item;
                return (
                    <div className="" key={id}>
                        {id == 1 ? (
                            <div className="row d-flex justify-content-evenly py-4  mb-3 align-items-center  erp_future_analytic allcoe_service_card_bg  whitebg_section discover_possible">
                                <div className="col-md-4 text-center col-sm-12 col-12 Eb_tech_advisory_imgBody">
                                    <div>
                                        <img src={require(`../../image/ERP/TechnologyAdvisory/${bannerImage}.png`)} className="img-fluid " />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-12">
                                    {
                                        desc.map((data, index) => (
                                            <div className="head_on_desc_body mb-3">
                                                {data.Desc_header && <p className="mb-3" style={{ fontWeight: 'bolder', lineHeight: '23px', fontSize: '27px' }}>{data.Desc_header}</p>}
                                                {data.Desc &&
                                                    data.Desc.map((value, index1) => (
                                                        <div className="explore_service_section" key={index1}>
                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                <div>
                                                                    <p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px !important' }} dangerouslySetInnerHTML={{ __html: value.text }}></p>
                                                                    {
                                                                        value.ul && value.ul.map((value1, index2) => (
                                                                            <li className="normal-text-font" dangerouslySetInnerHTML={{ __html: value1.li }} key={index2}></li>
                                                                        ))
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        ) : ''}

                        {(id === 2 || id === 6) && (
                            <div className={`row d-flex justify-content-around mt-3 mb-3 ${id == 6 ? 'allcoe_service_card_bg py-3' : ''}`}>
                                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                                    <div className=" ">
                                        <div className="container">
                                            <p className="text-center fw-bold" style={{ fontSize: '25px' }}>{title}</p>
                                            {
                                                desc.map((data, id) => (
                                                    <p className='line-_height text-center'>{data.text}</p>
                                                ))
                                            }
                                        </div>
                                        <div className="">
                                            {
                                                id == 4 ? (
                                                    <div className="row d-flex justify-content-center  Advisory_service ">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center ">
                                                            <img src={require(`../../image/ERP/TechnologyAdvisory/${bannerImage}.png`)} className=" accordian_img" />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column  " style={{ padding: '16px' }}>
                                                            {<Accordion className='d-flex flex-column EcCoe align-items-center'>
                                                                {accordians.map((item, index) => {
                                                                    const { header, desc } = item;
                                                                    return (
                                                                        <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                                                            <AccordionButton className='Dropdown-Bg-Color'
                                                                                onClick={() => handleToggle(index.toString())}
                                                                                aria-expanded={expandedItem === index.toString()}
                                                                            >
                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                    <span className='fw-bolder sm-font-size'>{header}</span>
                                                                                    {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                                </div>
                                                                            </AccordionButton>

                                                                            <Accordion.Body key={index} className='py-1'>
                                                                                {
                                                                                    desc && desc.map((item, index) => (
                                                                                        <p className='text-start' dangerouslySetInnerHTML={{ __html: item.text }} key={index}></p>
                                                                                    ))
                                                                                }
                                                                            </Accordion.Body>

                                                                        </Accordion.Item>
                                                                    );
                                                                })}
                                                            </Accordion>
                                                            }
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="row d-flex justify-content-center whitebg_section Advisory_service">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column  " style={{ padding: '16px' }}>
                                                            {<Accordion className='d-flex flex-column EcCoe align-items-center'>
                                                                {accordians.map((item, index) => {
                                                                    const { header, desc } = item;
                                                                    return (
                                                                        <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                                                            <AccordionButton className='Dropdown-Bg-Color'
                                                                                onClick={() => handleToggle(index.toString())}
                                                                                aria-expanded={expandedItem === index.toString()}
                                                                            >
                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                    <span className='fw-bolder sm-font-size'>{header}</span>
                                                                                    {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                                </div>
                                                                            </AccordionButton>

                                                                            <Accordion.Body key={index} className='py-1'>
                                                                                {
                                                                                    desc && desc.map((item, index) => (
                                                                                        <p className='text-start' dangerouslySetInnerHTML={{ __html: item.text }} key={index}></p>
                                                                                    ))
                                                                                }
                                                                            </Accordion.Body>

                                                                        </Accordion.Item>
                                                                    );
                                                                })}
                                                            </Accordion>
                                                            }
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center eb_workflow_img">
                                                            <img src={require(`../../image/ERP/TechnologyAdvisory/${bannerImage}.png`)} className=" accordian_img" />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {id === 3 && (
                            <div className="row d-flex justify-content-around mb-3">

                                <div className="col-12">
                                    {tabledata.map((item, index) => {
                                        const { id, image1, head, subData } = item;
                                        const rowColor = index % 2 === 0 ? ' ' : 'bg-white small_screen_col_reverse ';
                                        const isEven = index % 2 === 0;

                                        return (
                                            <div className={`col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end align-items-center  ${rowColor}`} key={id}>
                                                <div className="row d-flex flex-row align-items-center justify-content-around p-3 erp_application">
                                                    <div className="col-lg-10 col-md-10 col-sm-11 col-12 flex-row align-items-center">
                                                        <div className={`row flex-row align-items-center card-content ipad_col_rev erp_apm ${rowColor}`}>
                                                            {isEven ? (
                                                                <>

                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-start mb-3 Eb_tech_advisory_imgBody">
                                                                        <img src={require(`../../image/ERP/TechnologyAdvisory/${image1}.png`)} alt="HCM" className="img-fluid erp_stack_img_height" />
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column eb_tech_advisory  box-h2" style={{ color: "black" }}>
                                                                        <div>
                                                                            <p className="fw-bold fs-3" style={{ color: 'rgb(242,111,33)' }} >{head}</p>
                                                                            {
                                                                                subData.map((cards, index4) => (
                                                                                    <div className="Eb_Tech_subData mb-2">
                                                                                        <h5 className={`normal-text-font  fw-bold mb-1 `} key={index4}>{cards.text}</h5>
                                                                                        {
                                                                                            cards.ul.map((list, index3) => (
                                                                                                <li className="normal-text-font" dangerouslySetInnerHTML={{ __html: list.li }} key={index3}></li>
                                                                                            ))
                                                                                        }
                                                                                    </div>

                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                </>
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        )}

                        {
                            id == 4 && (
                                <div className="row d-flex justify-content-center align-items-center allcoe_service_card_bg mb-3">
                                    {title && <h3 className="text-center fw-bold hcm_offer_header mt-4">{title}</h3>}
                                    {desc && <div className="title_desc  d-flex flex-column align-items-center">
                                        {
                                            desc.map((value, items) => (
                                                value && <p key={items} className="text-center service_desc" style={{ width: '70%', fontSize: '16px' }}>{value.text}</p>
                                            ))
                                        }
                                    </div>
                                    }
                                    <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                                        {
                                            card.map((data, index) => {
                                                return <div className={`col-md-3  our_service_card col-dm-12 m-3 card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize Tech_Advisory-card-fix-height ${data.card_class}`}>

                                                    <div className='cardImg text-start d-flex justify-content-between align-items-center'>

                                                        <p className="fw-bold">{data.card_name}</p>
                                                        <img src={require(`../../image/ERP/TechnologyAdvisory/${data.icon}`)} alt="" className='icon-box' />
                                                    </div>
                                                    <div className="card-body px-1 d-flex">

                                                        <div className="onboarding_card_desc d-flex align-items-center" >
                                                            <p className="our_service_card_desc">{data.card_desc}</p></div>
                                                    </div>
                                                    {
                                                        data.card_url && (
                                                            <div className=" col-md-12 d-flex justify-content-end align-items-center">
                                                                <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                                    <p >{data.card_btn}</p></Link>
                                                                <Link to={data.card_url} >
                                                                    <button className="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }

                        {id === 5 && (
                            <div className="row d-flex justify-content-around mb-3">

                                <div className="col-12">
                                    {tabledata.map((item, index) => {
                                        const { id, image1, head, text, ul, CTA } = item;
                                        const rowColor = index % 2 === 0 ? ' ' : 'bg-white small_screen_col_reverse ';
                                        const isEven = index % 2 === 0;

                                        return (
                                            <div className={`col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end align-items-center  ${rowColor}`} key={id}>
                                                <div className="row d-flex flex-row align-items-center justify-content-around p-3 erp_application">
                                                    <div className="col-lg-10 col-md-10 col-sm-11 col-12 flex-row align-items-center">
                                                        <div className={`row flex-row align-items-center card-content ipad_col_rev erp_apm ${rowColor}`}>
                                                            {isEven ? (
                                                                <>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column  box-h2" style={{ color: "black" }}>
                                                                        <div>
                                                                            <p className="fw-bold fs-3" style={{ color: 'rgb(242,111,33)' }} >{head}</p>
                                                                            {text.map((paragraph, idx) => (
                                                                                <p className={`normal-text-font ${idx == 2 ? 'fw-bold' : 'mb-3'}`} key={idx}>{paragraph}</p>
                                                                            ))}

                                                                            {
                                                                                ul.map((list, index3) => (
                                                                                    <li className="normal-text-font" key={index3}>{list.li}</li>
                                                                                ))
                                                                            }
                                                                            <div className="ams_contact mt-3">
                                                                                <p className='fw-bold'>{CTA.text}</p>
                                                                                {CTA.btn1_Url && <div>
                                                                                    <a href={CTA.btn1_Url} target="_blank" className="btn HoverBtn" >{CTA.btn1}</a>

                                                                                </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-end mb-3">
                                                                        <img src={require(`../../image/ERP/AMS/${image1}.png`)} alt="HCM" className="img-fluid erp_stack_img_height" />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                </>
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        )}


                    </div>
                );
            })}
            <GetStarted />
        </>
    );
}

export default TechnologyAdvisoryServices