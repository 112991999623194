import React, { useState, useEffect } from "react";

const Carrer = () => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch user location using IP-based geolocation API
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setLocation(data.country_name || "Unknown");
      })
      .catch((err) => {
        setError("Failed to fetch location");
        console.error(err);
      });
  }, []);

  return (
    <div>
      {error && <p>Error: {error}</p>}
      {location ? (
        location === "India" ? (
          <p>Welcome to the India-specific content!</p>
        ) : (
          <p>Welcome to the content for {location}!</p>
        )
      ) : (
        <p>Loading location...</p>
      )}
    </div>
  );
};

export default Carrer; 