import React, { useEffect, useRef, useState } from "react";
import Banner from "./Banner";
import TrustedOrganization from "./TrustedOrganization";
import { Helmet } from "react-helmet";
import IndustryData from "../data/Industry.json";
import { Link } from "react-router-dom";
import "../style/Industry.css";
import { Carousel } from "react-bootstrap";

const Industries = () => {
  const videoRef = useRef(null);
  const statsRef = useRef(null);
  const [startCount, setStartCount] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth <= 991);

  useEffect(() => {
    // Autoplay fix for iOS
    const video = videoRef.current;
    if (video) {
      video.muted = true; // Ensure muted for autoplay
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise.catch(() => {
          setIsPaused(true); // Show play button if autoplay fails
        });
      }
    }
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handlePlay = () => setIsPaused(false);
    const handlePause = () => setIsPaused(true);

    if (videoElement) {
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
      }
    };
  }, []);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (!video) return;

    if (isPaused) {
      requestAnimationFrame(() => video.play());
    } else {
      requestAnimationFrame(() => video.pause());
    }
  };

  // Count Effect

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true); // Trigger counting when section is in view
        }
      },
      { threshold: 0.2 } // Trigger when 50% of the section is visible
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Load video when it enters the viewport
            setIsLoaded(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 } // Load when 50% of the video is visible
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Industries We Serve | Phenom&#x1D49;Cloud</title>
        <meta
          name="description"
          content="Explore industry-specific solutions at Phenomecloud. Tailored services for healthcare, automotive, life sciences, finance, retail, and more to meet your needs."
        />

        <meta
          property="og:title"
          content=">Industries We Serve | Phenom&#x1D49;Cloud"
        />
        <meta
          property="og:description"
          content="Explore industry-specific solutions at Phenomecloud. Tailored services for healthcare, automotive, life sciences, finance, retail, and more to meet your needs."
        />
        <meta
          name="keywords"
          content="Industry Specific Solutions | Healthcare Solutions | Life Sciences Solutions | Manufacturing Solutions | Financial Solutions | Retail Solutions | Technology Solutions "
        />
        <meta
          name="h1"
          content="Industry-Specific Solutions for Healthcare, Automotive, Finance, Retail & More | Phenomecloud"
        />
        <meta
          name="h2"
          content="Tailored Solutions for the Healthcare Industry"
        />
        <meta
          name="h2"
          content="Advanced Life Sciences Technology & Consulting"
        />
        <meta name="h2" content="Financial Solutions for Business Growth" />
        <meta
          name="h2"
          content="Transforming Manufacturing with Smart Solutions"
        />
        <meta
          name="h2"
          content="Retail Industry Services to Boost Efficiency & Growth"
        />
        <meta
          name="h2"
          content="Cutting-edge Technology Services for All Industries"
        />
        <meta
          name="h2"
          content="How Phenomecloud Helps Businesses in Various Sectors"
        />
      </Helmet>
      <Banner />
      {/* <TrustedOrganization /> */}
      <div className="EbCoe">
        {IndustryData.map((item, index) => {
          const {
            className,
            HeadingData,
            id,
            HeadingName,
            Desc,
            FirstBtnUrl,
            FirstBtnName,
            SecondBtnUrl,
            SecondBtnName,
            VideoUrl,
          } = item;
          return (
            <>
              {id === 1 && (
                <>
                  <div className="OurClientVideoBanner">
                    <div className="px-5 row justify-content-center align-items-center MainBannerPadding">
                      <div className="col-md-5 d-flex position-relative MainHeadingSectionContent">
                        <div className="HeadingSeaction">
                          <div className="HeadingBorder pt-5">
                            <h2 className="fw-bolder MainHeading">
                              {HeadingName}
                            </h2>
                          </div>
                          {
                            Desc.map((item,index) =>(
                                <h5
                                className="Heading mb-3 industryWeSupport_Description mt-3"
                                dangerouslySetInnerHTML={{ __html: item.text }}
                              ></h5>
 
                            ))
                          }
                         
                        </div>
                      </div>
                      <div className="col-md-7 p-0 position-relative">
                        <svg
                          id="edge"
                          data-name="edge"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 82.1 669.22"
                        >
                          <defs></defs>
                          <path
                            className="edge-1"
                            d="M0,669.22c32.72-85.31,53-203.78,53-334.72h-.1C52.9,203.64,32.66,85.28,0,0v669.22Z"
                          ></path>
                          <path
                            className="edge-2"
                            d="M0,0v669h10.04c34.85-81.61,56.86-201.22,56.86-334.5S44.89,81.61,10.04,0H0Z"
                          ></path>
                          <path
                            className="edge-2"
                            d="M13.1,0H0v669h13.1c41.8-75.12,68.99-196.95,68.99-334.5h0C82.1,196.95,54.91,75.12,13.1,0Z"
                          ></path>
                        </svg>
                        <video
                          className="VideoMainContent industry_videoBanner"
                          src={
                            isLoaded
                              ? require(`../image/OurClient/${VideoUrl}`)
                              : ""
                          }
                          // src={VideoUrl}
                          controls
                          playsinline="playsinline"
                          preload="none"
                          ref={videoRef}
                          tabIndex="-1"
                          role="application"
                          loop
                          muted
                          autoPlay
                        />
                        {!isScreenSmall && isPaused && (
                          <div
                            className="play-button-container"
                            onClick={handlePlayPause}
                          >
                            <button className="play-button">▶</button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {id === 2 && (
                <div className={`${className}`} key={index}>
                  {HeadingData &&
                    HeadingData.map((item, index) => {
                      const { className, Grid, CardSection } = item;
                      return (
                        <div className={className} key={index}>
                          <div className="Heading-Content justify-content-center text-center align-items-center row px-4 padding_remove">
                            {Grid.map((item, index) => {
                              const {
                                GridName,
                                className,
                                Name,
                                Header,
                                Description,
                              } = item;
                              return (
                                <div className={GridName} key={index}>
                                  <h4 className="fw-bold h3">{Header}</h4>
                                  <p
                                    className="pb-3"
                                    dangerouslySetInnerHTML={{
                                      __html: Description,
                                    }}
                                  ></p>
                                </div>
                              );
                            })}
                          </div>
                          {CardSection &&
                            CardSection.map((item, index) => {
                              const { className, CardData } = item;
                              return (
                                <div className={className} key={index}>
                                  <div className="card-section-content row px-5 justify-content-center text-center padding_remove inside-border-padding-remove">
                                    {CardData.map((item, index) => {
                                      const {
                                        GridName,
                                        className,
                                        ImgName,
                                        Heading,
                                        Name,
                                        Description,
                                        Url,
                                        DescriptionName,
                                        LastCard,
                                      } = item;
                                      return (
                                        <div className={GridName} key={index}>
                                          <Link
                                            to={Url}
                                            className="industry_card_link text-decoration-none text-black"
                                          >
                                            <div className={`${className}`}>
                                              <div className="cardImg text-start  text-black">
                                                <div className="industry_img_section  ">
                                                  {ImgName && (
                                                    <img
                                                      className="  mix-blend"
                                                      src={require(`../image/Industry/${ImgName}`)}
                                                      style={{
                                                        borderRadius: "23px",
                                                      }}
                                                      alt="Enterprise Business Solutions | Phenom&#x1D49;Cloud"
                                                    />
                                                  )}
                                                </div>
                                                <div className="industry_card_content_section">
                                                  <div className="catagory_section">
                                                    {/* {Heading && (
                                                      <p className="Description text-start m-0 MainHeading"></p>
                                                    )} */}
                                                    {Name && (
                                                      <h4
                                                        className="fw-bolder"
                                                        style={{
                                                          color: "#EF5713",
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                          __html: Name,
                                                        }}
                                                      ></h4>
                                                    )}
                                                  </div>
                                                  <div className="catagory_desc">
                                                    {Url && (
                                                      <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                        <Link
                                                          to={Url}
                                                          className="text-start text-decoration-none UrlText  "
                                                          href=""
                                                        >
                                                          {Description}
                                                        </Link>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              )}
               {id === 5 && (
                <div className="testimonialsMainSection industry_testimonials  position-relative overflow-hidden">
                  <div className="testimonialsSection pb-0 p-5">
                    <Carousel
                      interval={10000}
                      indicators={true}
                      controls={true}
                    >
                      {item.Testimonials.map((item, index) => {
                        const { title, ClientName, AddressSection } = item;
                        return (
                          <Carousel.Item
                            className="CarouselContent text-start"
                            key={index}
                          >
                            <h4
                              className="ClientHeadingName Description-Fontsize m-0 mb-2"
                              dangerouslySetInnerHTML={{ __html: ClientName }}
                            ></h4>
                            {title.map((item, index) => {
                              return (
                                <h6
                                  className="Description-Fontsize"
                                  key={index}
                                  dangerouslySetInnerHTML={{
                                    __html: item.Text,
                                  }}
                                ></h6>
                              );
                            })}
                            <div className="py-2 text-start">
                              <div className="CarouselAddressSection text-end Description-Fontsize">
                                {AddressSection}
                              </div>
                            </div>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              )}
              {id === 3 && (
                <div className="whatwethink_card light_blue_bg py-3">
                  <div className="whatwethink_header_section ">
                    <h3
                      className="h3 text-center fw-bold"
                      data-ui="blog-posts-header"
                    >
                      {item.HeadingName}
                    </h3>
                    <p className=" text-center">{item.Desc}</p>
                  </div>
                  <div className="row justify-content-center p-4">
                    {item.CardSection[0].CardData.map((item2, index1) => (
                      <div className="col-md-2 Press_details_cards industry_insights p-4 ">
                        <Link
                          to={item2.Url}
                          target={item2.target}
                          className="h-100 d-flex text-decoration-none flex-column justify-content-between"
                        >
                          <div className="Press_details_cards_im d-flex flex-column h-85 justify-content-between align-items-center">
                            <img
                              src={require(`../image/NavIcon/${item2.Img}`)}
                              alt="Press | PhenomeCloud"
                            />
                            <p className="py-3 text-dark">{item2.Heading}</p>
                          </div>
                          <div className="Press_details_cards_content">
                            {/* <p>{item2.Description}</p> */}
                            <button className="btn HoverBtn ">
                              Explore More
                            </button>
                          </div>
                          <div></div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              { (id === 4 || id === 6) && (
                <div className={`SuccessStoriesMainSection p-5 ${id === 6 ? 'light_blue_bg':''}`}>
                  <h3 className="h3 text-center fw-bold pb-3">
                    {item.Heading}
                  </h3>
                  <div className="SuccessStoriesSection industriesSuccessStories container">
                    <div className="row justify-content-center">
                      {item.Grid.map((item, index) => {
                        const {
                          GridName,
                          dataaos,
                          dataduration,
                          datadelay,
                          status,
                          Desc,
                          Img,
                          Url,
                        } = item;
                        return (
                          <div className={GridName} key={index}>
                            <Link
                              to={Url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="card h-100 text-decoration-none"
                            >
                              <div className="CardImgSection">
                                <img
                                  src={require(`../image/OurClient/${Img}`)}
                                  className="OurClientCaseStudyImg"
                                  alt="OurClient Image"
                                />
                              </div>
                              <div className="card-body d-flex flex-column justify-content-between" style={{padding:'6% 6% 8% 6%'}}> 
                                <p className="py-3 Description-Fontsize">
                                  {Desc}
                                </p>
                                <div className="SuccessStoriesStatus">
                                  <i class="animation"></i>
                                  {status} 
                                  <i class="animation"></i>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )} 
              {id === 7 && (
                <div
                  className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section HCM_cta_section light_blue_bg py-5`}
                >
                  <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                    <h1
                      className="Cloud_sloution_cta_header text-center fw-bold"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    ></h1>
                    <h5 className="Cloud_sloution_cta_Subheader HCM_cta_Subheader text-center mb-3">
                      {item.Desc}
                    </h5>
                    {item.cta_link && (
                      <Link to={item.cta_link} className="DownloadBtn">
                        {item.CTA}
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </>
  );
};

export default Industries;
