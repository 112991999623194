import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Banner from './Banner';
import '../style/Press.css'
import { FaChevronRight } from "react-icons/fa";
import { Helmet } from 'react-helmet';

const PressReleaseDetails = ({ adata }) => {
  const PressData = adata;

  return (
    <>
      <Banner />
      <div className="card cookiesPolicySection b-none pressdetails_body">
        <div className="card-body">
          <h4 style={{ color: "#f37021" }} className="card-title border-bottom border-dark mb-3 pb-3 text-center fw-bold" dangerouslySetInnerHTML={{ __html: PressData[0].Heading }}></h4>
          <h5 className="card-subtitle mb-2 border-bottom border-dark mb-3 d-inline border-2" dangerouslySetInnerHTML={{ __html: PressData[0].subHeading }}></h5>
          {
            PressData[0].Data.map((data, index) => (
              <div className="cookies_content" key={index}>
                {
                  data.Header !== "" ? <h5 className='mb-2 fw-bold' dangerouslySetInnerHTML={{ __html: data.Header }}></h5> : null
                }
                {
                  data.desc.map((data1, index1) => (
                    <p className="mb-2" dangerouslySetInnerHTML={{ __html: data1.Desc }} key={index1}></p>
                  ))
                }
                {
                  data.ul && (
                    <ul style={{ paddingLeft: '5%' }}>
                      {
                        data.ul.map((item, index) => (
                          <li className="mb-1" style={{ listStyleType: "circle" }} key={index} dangerouslySetInnerHTML={{ __html: item.list }}></li>
                        ))
                      }
                    </ul>
                  )
                }
                {
                  data.ListDesctiption && (
                    <>
                      {
                        data.ListDesctiption.map((item, index) => (
                          <p key={index} className="mb-2" dangerouslySetInnerHTML={{ __html: item.DescHeading }}></p>
                        ))
                      }
                      <ul>
                        {
                          data.ListDesctiption.map((item, index) => (
                            <li key={index} className="mb-1" style={{ listStyleType: "circle" }}><strong>{item.StrongHeading}</strong> {item.Desc}</li>
                          ))
                        }
                      </ul>
                    </>
                  )
                }
              </div>
            ))
          }
        </div>
      </div>

    </>
  );

};

export default PressReleaseDetails;