import React, { useState } from 'react'
import GetStart from '../../data/FooterSection/GetStarted.json';
import { GoArrowUpRight } from "react-icons/go";
import { Link, useParams } from 'react-router-dom';

export const GetStarted = () => {
    const [activeButton, setActiveButton] = useState('DemoBtn');

    const handleButtonClick = (button) => {
        setActiveButton(button);
    };

    let { className } = useParams();
    if (className === undefined) {
        className = 'home';
    }

    return (
        <div className="container-fluid my-1 GetStarted px-5 py-2 fix-p-remove">
            {GetStart.map((item, index) => {
                const { title, desc, Desc, subHeader, btn1, btn2, PageName, subDesc, Img, MainClass, btn1Url } = item;
                const isExternal = btn1Url?.startsWith("https:");
                const target = isExternal ? "_blank" : undefined;
                return (
                    className === PageName && (
                        <div className={`d-flex align-items-center flex-column text-start text-dark row p-5 get-start-inside-border-padding-remove ${MainClass ? MainClass : ''}`} key={index}>
                            <div className="Heading col-md-12 col-lg-12">
                                <h2 className="text-start title-font mt-3 fs-3 fw-bold col-md-6 " dangerouslySetInnerHTML={{ __html: title }}></h2>
                                {subHeader && (
                                    <p className="text-start title-font fw-bold mt-2 h5" dangerouslySetInnerHTML={{ __html: subHeader }}></p>
                                )}
                            </div>
                            <div className="row p-0 GetStart-flex-reverse">
                                <div className="Description col-md-6 d-flex flex-column justify-content-evenly get-start-inside-border-padding-remove getStartCardWidth">
                                    <div className="">
                                        {
                                            PageName === 'accelerator' || PageName === 'master_data_management_services' || PageName === 'enterprise_resource_planning_Analytics' ? (
                                                <div>
                                                    <p className="text-start title-font fw-bold mt-2">{subHeader}</p>
                                                    {
                                                        Desc && Desc.map((item1, index1) => (
                                                            <p className="text-start normal-text-font h6" dangerouslySetInnerHTML={{ __html: item1.text }}></p>
                                                        ))
                                                    }
                                                </div>
                                            ) : (
                                                <p className="text-start normal-text-font h6 py-1" dangerouslySetInnerHTML={{ __html: desc }}></p>
                                            )
                                        }

                                        {
                                            subDesc && (
                                                <p className="text-start normal-text-font h6 py-1 mb-0">{subDesc}</p>
                                            )
                                        }
                                        <div className="text-start w-100 d-flex GetStartBtnSetions mt-3">
                                            {
                                                btn1 && (
                                                    isExternal ? (<a href={btn1Url} target={target} rel={target === "_blank" ? "noopener noreferrer" : undefined} className={`DemoBtn button-text btn-dark text-decoration-none d-flex align-items-center ${activeButton === 'DemoBtn' ? 'active-btn' : ''}`} onClick={() => handleButtonClick('DemoBtn')}>
                                                        {btn1}<GoArrowUpRight />
                                                    </a>) : (
                                                        <Link to={btn1Url} className={`DemoBtn button-text btn-dark text-decoration-none d-flex align-items-center ${activeButton === 'DemoBtn' ? 'active-btn' : ''}`} onClick={() => handleButtonClick('DemoBtn')}>
                                                            {btn1}<GoArrowUpRight />
                                                        </Link>
                                                    )

                                                )
                                            }


                                            {btn2 && <Link to={'/contact'} className={`ContactBtn button-text btn-dark text-decoration-none ${activeButton === 'ContactUsBtn' ? 'active-btn' : ''}`} onClick={() => handleButtonClick('ContactUsBtn')}>
                                                {btn2} <GoArrowUpRight />
                                            </Link>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6 text-center getStartCardWidth position-relative">
                                    <img alt={`${className} | Phenom&#x1D49;Cloud`} className='GetStartImg' src={require(`../../image/GetStarted/${Img}`)} />
                                </div>

                            </div>
                        </div>)
                )
            })}

        </div>
    );
}
