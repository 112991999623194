import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState("Eng");

    useEffect(() => {
        const storedLanguage = Cookies.get("selectedLanguage") || "Eng";
        setLanguage(storedLanguage);
    }, []);

    const handleLanguageChange = (selectedLanguage) => {
        setLanguage(selectedLanguage);
        Cookies.set("selectedLanguage", selectedLanguage, { expires: 7 });
    };

    return (
        <LanguageContext.Provider value={{ language, handleLanguageChange }}>
            {children}
        </LanguageContext.Provider>
    );
};
