import React, { useEffect, useRef, useState } from 'react'
import { GetStarted } from './FooterSection/GetStarted';
import TrustedOrganization from './TrustedOrganization';
// import Carousel from 'react-bootstrap/Carousel';
import { Helmet } from 'react-helmet';
import '../style/About.css';
import { Accordion } from 'react-bootstrap';

import Marquee from "react-fast-marquee";
import { AccordionButton } from "react-bootstrap";
import AboutUsPhenomecloud from '../image/About/About Us Phenomecloud.mp4'
import { Link } from 'react-router-dom';
import Aos from 'aos';
import { GoChevronDown, GoChevronRight } from 'react-icons/go';


const Banner = React.lazy(() => import('./Banner'));
export const About = ({ adata }) => {
    const videoRef = useRef(null);
    const [expandedItem, setExpandedItem] = useState(null);
    const accordianObject = adata.find(item => item.Id === 7);
    const [isPaused, setIsPaused] = useState(false);
    const accordianData = accordianObject?.accordianData || [];
    const [activeImage, setActiveImage] = useState(accordianData[0]?.Img);
    const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth <= 991);


    const handleToggle = (eventKey, index) => {
        setExpandedItem((prev) => {
            const isSame = prev === eventKey;
            setActiveImage(isSame ? accordianData[0]?.Img : accordianData[index]?.Img);
            return isSame ? null : eventKey; // Single state update
        });
    };
    React.useEffect(() => {

    }, [activeImage]);
    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPaused) {
                videoRef.current.play();
                setIsPaused(false);
            } else {
                videoRef.current.pause();
                setIsPaused(true);
            }
        }
    };
    useEffect(() => {
        Aos.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const TrustedOrganization2 = () => {
        const org = adata[5]?.org || [];
        const [itemsPerRow, setItemsPerRow] = useState(4);

        useEffect(() => {
            const handleResize = () => {
                const width = window.innerWidth;
                if (width <= 767) {
                    setItemsPerRow(1);
                } else if (width <= 1300) {
                    setItemsPerRow(2);
                } else {
                    setItemsPerRow(3);
                }
            };
            handleResize();
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);

        const groupedItems = [];
        for (let i = 0; i < org.length; i += itemsPerRow) {
            groupedItems.push(org.slice(i, i + itemsPerRow));
        }

        return (
            <div className="container-fluid TrustedBrand">
                <div className="row justify-content-center">
                    <Marquee speed={150} delay={0} >
                        {groupedItems.map((group, rowIndex) => (
                            <div className="row mb-3 " key={rowIndex} style={{ marginLeft: '10px' }}>

                                {group.map((item, index) => {
                                    const { Img, Url } = item;
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 h-100" key={index}>
                                            <img
                                                className="img-fluid trusted-logo w-100"
                                                src={require(`../image/About/${Url}`)}
                                                alt={`Logo ${index}`}
                                                style={{
                                                    objectFit: "cover",
                                                    height: "300px",
                                                    width: "100%",
                                                    borderRadius: "8px",
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </Marquee>

                </div>
            </div>
        );
    };



    const [hoveredValue, setHoveredValue] = useState(null);

    var aboutUs = adata
    return (
        <>
            <Helmet>
                <title>Phenom&#x1D49;Cloud | About Us</title>
                <meta name="description" content="PhenomeCloud is a technology solutions provider committed to empowering businesses with innovative tools and tailored support, maximizing the value of your technology investments." />
            </Helmet>

            {/* <Banner /> */}
            {/* <TrustedOrganization /> */}
            {/* <div className='p-lg-5'>
                <div className="px-lg-5 px-3 row justify-content-center align-items-center MainBannerPadding">
                    <div className=" my-2 col-xl-6 col-lg-6 d-flex position-relative MainHeadingSectionContent"                    >
                        <div className="HeadingSeaction content-fontsize">
                            <div className='HeadingBorder'>
                                <h2 className='fw-bolder MainHeading'>Who we are</h2>
                            </div>        
                            <p>At <strong>Phenom<sup>e</sup>Cloud</strong>, we are pioneers in driving enterprise digital transformation, specializing in cloud, business, ERP, and people-centric solutions. Our innovation hubs are the cornerstone of delivering groundbreaking digital solutions tailored to address your organization's unique challenges and opportunities. By blending deep expertise with global best practices, we empower you to enhance performance, foster continuous improvement, and achieve measurable, long-term results.</p>
                            <p>Our agile, scalable solutions create resilient technology frameworks, allowing your organization to quickly adapt to market shifts. This boosts customer satisfaction and employee engagement, accelerates revenue growth, and improves profitability.</p>
                            <p>From AI-driven insights and HRIS technologies to enterprise analytics, ERP systems, and cloud solutions, we provide seamlessly integrated tools to help you navigate today’s dynamic business landscape. Together, we’ll unlock new avenues for agility, innovation, and sustained success—propelling your organization toward a future of excellence and transformation.</p>
                            <p>Let’s collaborate to build a brighter, more successful tomorrow. </p>
                            <div className="d-flex justify-content-between HeadingBtnSection">
                                <button className='DownloadBtn my-2'>
                                    <span>Download Brochure</span>
                                </button>
                                <button className='GetInTouchBtn my-2'>
                                    <span>Get In Touch</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="my-2 col-xl-6 col-lg-6 p-0 position-relative">
                        <svg style={{left: '-2px'}} id="edge" data-name="edge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.1 669.22">
                            <defs>
                            </defs>
                            <path className="edge-1" d="M0,669.22c32.72-85.31,53-203.78,53-334.72h-.1C52.9,203.64,32.66,85.28,0,0v669.22Z"></path>
                            <path className="edge-2" d="M0,0v669h10.04c34.85-81.61,56.86-201.22,56.86-334.5S44.89,81.61,10.04,0H0Z" ></path>
                            <path className="edge-2" d="M13.1,0H0v669h13.1c41.8-75.12,68.99-196.95,68.99-334.5h0C82.1,196.95,54.91,75.12,13.1,0Z" ></path>
                        </svg>
                        <video className='VideoMainContent'
                            src={AboutUsPhenomecloud}
                            controls
                            playsinline="playsinline" preload="none"
                            ref={videoRef}
                            playsInline
                            tabIndex="-1"
                            role="application"
                            loop
                            muted
                            autoPlay
                        />
                        {isPaused && (
                            <div className="play-button-container" onClick={handlePlayPause}>
                                <button className="play-button">▶</button>
                            </div>
                        )}
                    </div>
                </div>
            </div> */}

            <div className="container-fluid p-0 overflow-hidden">
                {
                    aboutUs.map((item) => {
                        const { Id, accordianData, Heading, Img, Data, Description, btnText, btnUrl, CarouselData, HeadingName, Desc, FirstBtnName, FirstBtnUrl, SecondBtnUrl, SecondBtnName, VideoUrl } = item;
                        return (
                            <div key={Id}>
                                {
                                    Id === 1 && (
                                        <div className='px-xl-5'>
                                            <div className="mt-5 pt-2 px-md-5 row justify-content-center align-items-center MainBannerPadding aboutUs">
                                                <div className="SubBanner col-xl-6 col-lg-10 col-md-12  d-flex position-relative MainHeadingSectionContent"
                                                    data-aos="fade-right" data-aos-duration="1000">
                                                    <div className="HeadingSeaction">
                                                        <div className='HeadingBorder'>
                                                            <h2 className='fw-bolder MainHeading'>{HeadingName}</h2>
                                                        </div>
                                                        {
                                                            Desc && Desc.map((item) => {
                                                                return (
                                                                    <p className="m-0 mb-2 videoDescription" dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                                )
                                                            })
                                                        }
                                                        <div className="d-flex HeadingBtnSection">
                                                            {
                                                                FirstBtnUrl && (
                                                                <Link to={FirstBtnUrl} className='DownloadBtn my-2 text-decoration-none mx-2'>
                                                                    <span>{FirstBtnName}</span>
                                                                </Link>
                                                                )
                                                            }
                                                            {
                                                                SecondBtnUrl && (
                                                                    <Link to={SecondBtnUrl} className='GetInTouchBtn my-2 mx-2 text-decoration-none text-center'>
                                                                        <span>{SecondBtnName}</span>
                                                                    </Link>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" SubBanner col-xl-6 col-lg-10  col-md-12  position-relative">
                                                    <svg style={{height: '550px'}} id="edge" data-name="edge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.1 669.22">
                                                        <defs>
                                                        </defs>
                                                        <path className="edge-1" d="M0,669.22c32.72-85.31,53-203.78,53-334.72h-.1C52.9,203.64,32.66,85.28,0,0v669.22Z"></path>
                                                        <path className="edge-2" d="M0,0v669h10.04c34.85-81.61,56.86-201.22,56.86-334.5S44.89,81.61,10.04,0H0Z" ></path>
                                                        <path className="edge-2" d="M13.1,0H0v669h13.1c41.8-75.12,68.99-196.95,68.99-334.5h0C82.1,196.95,54.91,75.12,13.1,0Z" ></path>
                                                    </svg>
                                                    <video className='VideoMainContent'
                                                        src={require(`../image/About/${VideoUrl}`)}
                                                        controls
                                                        playsinline="playsinline" preload="none"
                                                        ref={videoRef}
                                                        tabIndex="-1"
                                                        role="application"
                                                        loop
                                                        muted
                                                        autoPlay
                                                    />
                                                    {/* {isPaused && (
                                                        <div className="play-button-container d-flex justify-content-center align-items-center" onClick={handlePlayPause}>
                                                            <button className="play-button"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                                                                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
                                                            </svg></button>
                                                        </div>
                                                    )}
                                                    {!isPaused && (
                                                        <div className="play-button-container" onClick={handlePlayPause}>
                                                            <button className="play-button"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 16">
                                                                <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5m5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5" />
                                                            </svg></button>
                                                        </div>
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className='my-3'>

                                                <TrustedOrganization />
                                            </div>
                                        </div>

                                    )
                                }
                                {Id === 2 &&
                                    <div className='my-1'>
                                        <div className="container our-values-container">
                                            <h2 style={{ fontWeight: 'bold' }}>{Heading}</h2>
                                            <div className="row my-5">
                                                {Data.map((value, index) => (
                                                    <div
                                                        key={index}
                                                        className={`col-lg-4 ${index === Data.length - 1 ? 'col-md-8' : 'col-md-6'} col-sm-12 mb-4`}
                                                    >
                                                        <div
                                                            className="value-card"
                                                            style={{ backgroundColor: value.color }}
                                                            onMouseEnter={() => setHoveredValue(value)}
                                                            onMouseLeave={() => setHoveredValue(null)}
                                                        >
                                                            <h3>{value.title}</h3>
                                                            {hoveredValue && hoveredValue.title === value.title && (
                                                                <div
                                                                    className="value-detail"
                                                                    style={{ transition: 'transform 0.3s ease-in' }}
                                                                >
                                                                    <p dangerouslySetInnerHTML={{ __html: value.description }}></p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>


                                    </div>

                                }
                                {
                                    Id === 4 && (
                                        <div className="row justify-content-center container mx-auto ">
                                            <div className="col-md-11 py-3 d-flex align-items-center flex-column">
                                                <div className="team-heading text-center mb-2">
                                                    <h2 className="fw-bold">{Heading}</h2>
                                                </div>
                                                <div className="text-center">
                                                    <p dangerouslySetInnerHTML={{ __html: Description }} />
                                                </div>
                                                {(
                                                    <div className="text-start w-100 d-flex GetStartBtnSetions mt-3 justify-content-center">
                                                        <a href={btnUrl} className="DemoBtn button-text btn-dark text-decoration-none d-flex align-items-center active-btn">
                                                            {btnText}
                                                        </a>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    )
                                }
                                {

                                }
                                {
                                    Id === 7 && (
                                        <div className='OurClientaccordionMainSection px-5'>
                                            <div className="OurClientaccordionSection px-4">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6">
                                                        <Accordion>
                                                            {
                                                                accordianData.map((item, index) => {
                                                                    const { Heading, Description } = item;
                                                                    return (
                                                                        <Accordion.Item eventKey={index} className='mb-2 acoordian_items w-100' style={{ border: 'none' }} key={index}>
                                                                            <AccordionButton className='OurClientDropdown-Bg-Color'
                                                                                onClick={() => handleToggle(index.toString(), index)}
                                                                                aria-expanded={expandedItem === index.toString()}
                                                                            >
                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                    <h4 className='fw-bolder sm-font-size'>{Heading}</h4>
                                                                                    {expandedItem === index.toString() ? <GoChevronDown className='OurClientAccordionBtn' /> : <GoChevronRight className='OurClientAccordionBtn' />}
                                                                                </div>
                                                                            </AccordionButton>
                                                                            <Accordion.Body key={index} className='py-1'>
                                                                                {
                                                                                    Description && Description.map((item) => {
                                                                                        return (

                                                                                            <p className='text-start' dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {
                                                                                    isScreenSmall && (
                                                                                        <div className="imgSection d-flex justify-content-center">
                                                                                            {
                                                                                                activeImage ? (
                                                                                                    <img
                                                                                                        src={require(`../image/About/${activeImage}`)}
                                                                                                        alt="OurClient"
                                                                                                        loading="lazy"
                                                                                                        className={activeImage ? 'image-transition w-100' : ' w-100'}
                                                                                                        // className="w-100 image-exit "
                                                                                                        onLoad={(e) => e.target.classList.add("image-transition")}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <p>No image available</p>
                                                                                                )}
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    )
                                                                })
                                                            }
                                                        </Accordion>
                                                    </div>
                                                    {
                                                        !isScreenSmall && (
                                                            <div className="col-md-6">
                                                                <div className="imgSection text-center">
                                                                    {
                                                                        activeImage ? (
                                                                            <img
                                                                                src={require(`../image/About/${activeImage}`)}
                                                                                alt="OurClient"
                                                                                loading="lazy"
                                                                                className={activeImage ? 'image-transition w-100' : ' w-100'}
                                                                                // className="w-100 image-exit "
                                                                                onLoad={(e) => e.target.classList.add("image-transition")}
                                                                            />
                                                                        ) : (
                                                                            <p>No image available</p>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }



                            </div>
                        )
                    })
                }



            </div>
            <TrustedOrganization2 />
            <GetStarted />
        </>
    )
}
