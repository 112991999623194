import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Banner from './Banner';
import '../style/Press.css'
import { FaChevronRight } from "react-icons/fa";
import { Helmet } from 'react-helmet';

const PressRelease = () => {
 

  return (
    <>
     <Helmet>
                <title>Phenom&#x1D49;Cloud | In the Press - Latest News & Media Highlights</title>
                <meta
                    name="description"
                    content="Stay updated with Phenom eCloud's latest news, media features, and industry highlights. Discover how we’re shaping the future of technology and cloud innovation."
                />
                <meta
                    name="keywords"
                    content="Cloud Migration | Cloud optimization |Cloud Security | DevOps & Automation | Azure®, AWS®, Google® Cloud Support"
                />
                <meta
                    name="robots"
                    content="index, follow"
                />
                <meta
                    property="og:title"
                    content=">Enterprise Cloud | Phenom&#x1D49;Cloud"
                />
                <meta
                    property="og:description"
                    content="Phenomecloud offers expert cloud solutions across Azure®, AWS®, and Google Cloud®, including migration, DevOps, security, cost optimization, managed services, and 24/7 support."
                />
                <meta
                    property="og:keywords"
                    content="Cloud Migration | Cloud optimization |Cloud Security | DevOps & Automation | Azure®, AWS®, Google® Cloud Support"
                />
                 
            </Helmet>
      <Banner />
      <div
        className="pressRelease_body_content  "
        style={{ backgroundColor: "#f8f8f4" }}
      >
        <div className="d-flex justify-content-center">
          <section className="row container press_contact_section">
            <h2 className="h2 text-center">Contact us</h2>
            <div className="press-link">
              <a href="mailto:press@phenomecloud.com">press@phenomecloud.com</a>
            </div>
            <div className="twelve">
              <p className="paragraph">
                At Phenom<sup>e</sup>Cloud, we design cutting-edge technology
                that empowers people to connect, collaborate, and scale their
                businesses seamlessly. Our solutions drive innovation,
                streamline operations, and open doors to new opportunities,
                enabling growth in today's ever-evolving digital landscape.
              </p>
              <p className="paragraph">
                Please get in touch by sending us at email
                to{" "}
                <a href="mailto:press@phenomecloud.com">
                  press@phenomecloud.com
                </a>
                .
              </p>
            </div>
          </section>
        </div>

        <div class="stats-container">
          <section class="container">
            <div class="row justify-content-center">
              <div class="four">
                <div id="about_stat_founded" class="stat">
                  <h1>2010</h1>
                  <p>Founded</p>
                </div>
              </div>
              <div class="four">
                <div id="about_stat_employees" class="stat">
                  <h1>~50+</h1>
                  <p>Countries</p>
                </div>
              </div>
              <div class="four">
                <div id="about_stat_items" class="stat">
                  <h1>More than 100</h1>
                  <p>Million Lines of code</p>
                </div>
              </div>
              <div class="four">
                <div id="about_stat_sellers" class="stat">
                  <h1>300+</h1>
                  <p>Trusted Clients</p>
                </div>
              </div>
              <div class="four">
                <div id="about_stat_buyers" class="stat">
                  <h1>400+</h1>
                  <p>Global Transformation Initiatives</p>
                </div>
              </div>
              <div class="four d-none"></div>
            </div>
            <div class="row">
              <div class="four">
                <div id="about_stat_hq_location" class="stat">
                  <p>Headquartered in the </p>
                  <h1>Irving, Texas, USA.</h1>
                  {/* <p>neighborhood of </p>
                  <h1>Brooklyn, NY</h1> */}
                </div>
              </div>
              <div class="four">
                <div id="about_stat_global_offices" class="stat">
                  <p className="d-flex flex-column">
                    With additional offices in
                    <h1>India - Vijayawada</h1>
                  </p>
                </div>
              </div>
              <div class="four">
                <div id="about_stat_user_countries" class="stat">
                  <br />
                  <h1>India - Hyderabad</h1>
                </div>
              </div>
            </div>
            {/* <div class="row justify-content-center"> 
              <div class="four">
                <div id="about_etsy_fact_sheet" class="stat">
                  <p>
                    Etsy Fact Sheet
                    <img
                      className="fact_sheet_img"
                      alt="Etsy Fact Sheet"
                      src="https://i.etsystatic.com/inv/b8a612/5783579870/inv_fullxfull.5783579870_8tq90xmy.jpg?version=0"
                      title="Etsy Fact Sheet"
                    />
                    <a
                      style={{ color: "#FFFFFF" }}
                      href="https://storage.googleapis.com/etsy-extfiles-prod/Press/2024_fact_sheet.pdf"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Read Etsy Fact Sheet
                    </a>
                  </p>
                </div>
              </div> 
            </div> */}
            <div class="eight offset-two">
              <div id="about_stat_as_of_date" class="small-byline">
                {/* <p className="d-flex flex-column align-items-center">
                  As of September 30, 2024
                  <em>
                    All figures are consolidated to include the Etsy, Reverb,
                    and Depop marketplaces
                  </em>
                </p> */}
              </div>
            </div>
          </section>
        </div>

        <div className="d-flex justify-content-center">
          <section className="row container news-blog">
            <h2 className="h2 text-center">
              What’s new at Phenom<sup>e</sup>Cloud
            </h2>
            <h3 className="h3 text-center" data-ui="blog-posts-header">
              From the Digital Journal
            </h3>

            <ul data-ui="blog-posts--news-blog"></ul>

            <p className="paragraph text-center clear">
              <a href="https://blog.phenomecloud.com/" target="_blank">
                Read more on the Phenom<sup>e</sup> News Blog
              </a>
              <span>
                <FaChevronRight />
              </span>
            </p>

            <div className="clear"></div>
            <hr className="spacer" />
            <div className="row justify-content-center">
              <h3 className="h3 text-center" data-ui="blog-posts-header">
                The Latest Press Releases About Phenom<sup>e</sup>Cloud
              </h3>
              <div className="col-md-5 Press_details_cards p-4 ">
                <Link
                  to={"/PressReleaseDetails"}
                  className="h-100 d-flex text-decoration-none flex-column justify-content-between"
                >
                  <div className="Press_details_cards_img">
                    <img
                      src={require(`../image/navLogo.png`)}
                      alt="Press | PhenomeCloud"
                    />
                  </div>
                  <div className="Press_details_cards_content">
                    <p>
                    Phenom<sup>e</sup>Cloud Acquires Pixentia to Bolster Leadership in
                      Enterprise Digitalization and AI Innovation
                    </p>
                  </div>
                  <div>
                    {/* <a
                      href="https://blog.phenomecloud.com/"
                      target="_blank"
                      className="cta_btn  fs-6"
                      style={{ textDecoration: "none", color: "#FFFFFF" }}
                    >
                      Explore More
                    </a> */}
                  </div>
                </Link>
              </div>
              {/* <div className="col-md-5 Press_details_cards">b</div> */}
            </div>
            {/* <h3 className="h3 text-center h3-empty">
              <Link  to={'/PressReleaseDetails'}>
               Phenom<sup>e</sup>cloud Press Releases
              </Link>
            </h3> */}
          </section>
        </div>

        {/* <div className="d-flex justify-content-center">
          <div class="white w-100 impact">
            <section class="container assets">
              <h2 class="h2 text-center">Brand assets</h2>
              <div class="row">
                <div class="four">
                  <h3 class="asset-label normal">Logo</h3>
                  <div class="asset etsy-logo-asset">
                    <div class="button-overlay">
                      <a
                        class="asset-button"
                        href="https://extfiles.etsy.com/Press/etsy-logo.zip"
                      >
                        Download
                        <span class="screen-reader-only">Logo/span&gt;</span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="four">
                    <h3 class="asset-label normal">Office photos</h3>
                    <div class="asset office-asset-one">
                      <div class="button-overlay">
                        <a
                          class="asset-button"
                          href="http://extfiles.etsy.com/Press/dumbo-office/EtsytoriumExterior.jpg"
                        >
                          Download
                          <span class="screen-reader-only">Etsytorium</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="four">
                    <div class="asset-label"></div>
                    <div class="asset office-asset-two">
                      <div class="button-overlay">
                        <a
                          class="asset-button"
                          href="http://extfiles.etsy.com/Press/dumbo-office/EtsyLabs.jpg"
                        >
                          Download
                          <span class="screen-reader-only">Etsy Labs</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="four">
                    <div class="asset-label"></div>
                    <div class="asset office-asset-six">
                      <div class="button-overlay">
                        <a
                          class="asset-button"
                          href="http://extfiles.etsy.com/Press/dumbo-office/EtsyMural.jpg"
                        >
                          Download
                          <span class="screen-reader-only">Etsy mural</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="four">
                    <div class="asset-label"></div>
                    <div class="asset office-asset-three">
                      <div class="button-overlay">
                        <a
                          class="asset-button"
                          href="http://extfiles.etsy.com/Press/hudson-office/hudson_office_011.jpg"
                        >
                          Download
                          <span class="screen-reader-only">Hudson Office</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="four">
                    <div class="asset-label"></div>
                    <div class="asset office-asset-four">
                      <div class="button-overlay">
                        <a
                          class="asset-button"
                          href="http://extfiles.etsy.com/Press/hudson-office/hudson_office_013.jpg"
                        >
                          Download
                          <span class="screen-reader-only">Hudson Office</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="four">
                    <div class="asset-label"></div>
                    <div class="asset office-asset-five">
                      <div class="button-overlay">
                        <a
                          class="asset-button"
                          href="http://extfiles.etsy.com/Press/hudson-office/hudson_office_034.jpg"
                        >
                          Download
                          <span class="screen-reader-only">Hudson Office</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="four">
                    <h3 class="asset-label normal">Video B-Roll</h3>
                    <div class="asset video-bkgd">
                      <div class="button-overlay">
                        <a
                          class="asset-button"
                          href="http://extfiles.etsy.com/Press/ETSY-VIDEO-HD.mp4"
                        >
                          Download
                          <span class="screen-reader-only">Video B-Roll</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div> */}
      </div> 
    </>
  );
};

export default PressRelease;
