import React, { useEffect, useState } from 'react'
import TrustedOrganization from '../TrustedOrganization'
import { Link } from 'react-router-dom'
import { GetStarted } from '../FooterSection/GetStarted'
import FooterBrands from '../FooterBrands'
import { Helmet } from 'react-helmet';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Accordion, AccordionButton } from 'react-bootstrap'
import { GoHorizontalRule, GoPlus } from 'react-icons/go'
const Banner = React.lazy(() => import('../Banner'));

export const ERP_COE = ({ adata }) => {
    const ERPCOE = adata;
    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };
    useEffect(() => {
        Aos.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 1269);
        };

        window.addEventListener('resize', updateScreenSize);

        updateScreenSize();

        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>Enterprise ERP | Phenom&#x1D49;Cloud</title>
                <meta
                    name="description"
                    content="Discover tailored ERP solutions for enterprises to streamline operations, enhance efficiency, and drive growth. Transform your business with PhenomeCloud."
                />
                <meta
                    name="keywords"
                    content="ERP Solutions |ERP Consulting | Cloud ERP Services | ERP Implementation Services | ERP Integration Services"
                />
                <meta
                    name="robots"
                    content="index, follow"
                />
                <meta
                    property="og:title"
                    content="Comprehensive ERP Solutions: Advisory, Consulting, Implementation & More"
                />
                <meta
                    property="og:description"
                    content="Discover tailored ERP solutions for enterprises to streamline operations, enhance efficiency, and drive growth. Transform your business with PhenomeCloud."
                />
                <meta
                    property="og:keywords"
                    content="ERP Solutions |ERP Consulting | Cloud ERP Services | ERP Implementation Services | ERP Integration Services"
                />
            </Helmet>
            <Banner />
            <TrustedOrganization />

            <div className="ErpCoe">
                {
                    ERPCOE.map((item, index) => {
                        const { id, title, subTitle, desc, subDesc } = item
                        return (
                            id === 10 &&
                            <div className="row d-flex justify-content-center align-items-center py-4 default_bg_color px-5 Enterprise_Digitalization_partner_section" key={index}>
                                <h3 className="Cloud_sloution_header text-center mb-2 fw-bold" dangerouslySetInnerHTML={{ __html: title }}></h3>
                                <p className="col-10 text-start mb-2" dangerouslySetInnerHTML={{ __html: subTitle }}></p>
                                <div className="col-md-10 d-flex flex-column justify-content-center align-items-center">
                                    <div className="row card-body px-1 d-flex ipad_col_rev">

                                        <div className="col-md-6 col-sm-12  ipad_full_width Enterprise_Digitalization_left_content" style={{ padding: "1%" }}>
                                            {
                                                desc.map((item, index) => (
                                                    index < 3 ? (
                                                        item.ul ? (
                                                            <>
                                                                <div className="d-flex col-md-11 align-items-center">
                                                                    {
                                                                        item.icon && <span style={{ marginRight: '.5%' }}> <img alt="Enterprise ERP Solutions | Phenom&#x1D49;Cloud" src={require(`../../image/Agile/${item.icon}`)} className="me-2" /> </span>
                                                                    }
                                                                    <p className="our_service_card_desc" dangerouslySetInnerHTML={{ __html: item.Desc }}></p>
                                                                </div>
                                                                <ul className="Enterprise_Digital_list">
                                                                    {
                                                                        item.ul && <ul>
                                                                            {item.ul.map((list, index) => (
                                                                                <li key={index} dangerouslySetInnerHTML={{ __html: list.li }}></li>
                                                                            ))}
                                                                        </ul>
                                                                    }
                                                                </ul>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="d-flex col-md-11  mb-3">
                                                                    {
                                                                        item.icon && <span style={{ marginRight: '.5%' }}> <img alt="Enterprise ERP Solutions | Phenom&#x1D49;Cloud" src={require(`../../image/Agile/${item.icon}`)} className="me-2" /> </span>
                                                                    }
                                                                    <p className="our_service_card_desc" dangerouslySetInnerHTML={{ __html: item.Desc }}></p>
                                                                    {
                                                                        item.ul && <ul>
                                                                            {item.ul.map((list, index) => (
                                                                                <li key={index} dangerouslySetInnerHTML={{ __html: list.li }}></li>
                                                                            ))}
                                                                        </ul>
                                                                    }
                                                                </div>
                                                            </>
                                                        )
                                                    ) : ("")

                                                ))
                                            }

                                        </div>

                                        <div className="col-md-6 col-sm-12  ipad_full_width Enterprise_Digitalization_right_content" style={{ padding: "1%" }}>
                                            {
                                                desc.map((item, index) => (
                                                    index > 2 ? (
                                                        item.ul ? (
                                                            <>
                                                                <div className="d-flex col-md-11 align-items-center">
                                                                    {
                                                                        item.icon && <span style={{ marginRight: '.5%' }}> <img alt="Enterprise ERP Solutions | Phenom&#x1D49;Cloud" src={require(`../../image/Agile/${item.icon}`)} className="me-2" /> </span>
                                                                    }
                                                                    <p className="our_service_card_desc" dangerouslySetInnerHTML={{ __html: item.Desc }}></p>
                                                                </div>
                                                                <ul className="Enterprise_Digital_list">
                                                                    {
                                                                        item.ul && <ul>
                                                                            {item.ul.map((list, index) => (
                                                                                <li key={index} dangerouslySetInnerHTML={{ __html: list.li }}></li>
                                                                            ))}
                                                                        </ul>
                                                                    }
                                                                </ul>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="d-flex col-md-11  mb-3">
                                                                    {
                                                                        item.icon && <span style={{ marginRight: '.5%' }}> <img alt="Enterprise ERP Solutions | Phenom&#x1D49;Cloud" src={require(`../../image/Agile/${item.icon}`)} className="me-2" /> </span>
                                                                    }
                                                                    <p className="our_service_card_desc" dangerouslySetInnerHTML={{ __html: item.Desc }}></p>
                                                                    {
                                                                        item.ul && <ul>
                                                                            {item.ul.map((list, index) => (
                                                                                <li key={index} dangerouslySetInnerHTML={{ __html: list.li }}></li>
                                                                            ))}
                                                                        </ul>
                                                                    }
                                                                </div>
                                                            </>
                                                        )
                                                    ) : ("")

                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="d-flex  flex-column align-items-center padding_fix col-10 text-center">
                                    {
                                        subDesc.map((item2, index2) => (
                                            <p className="our_service_card_desc" dangerouslySetInnerHTML={{ __html: item2.text }}></p>
                                        ))
                                    }
                                </div>

                            </div>
                        )
                    })
                }
                {
                    ERPCOE.map((item, index) => {
                        const { id, Header, Desc, cta_link, CTA } = item;
                        return (
                            id === 5 &&
                            <div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section white_bg py-4`} key={index}>
                                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="Cloud_sloution_cta_header text-center fw-bold ">{Header}</h1>
                                    <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">{Desc}</h3>
                                    {
                                        cta_link &&
                                        <button className="cta_btn">
                                            {
                                                cta_link === '/demo' ? (
                                                    <Link to={cta_link}>{CTA}</Link>
                                                ) : (
                                                    <a href={cta_link} target='_blank'>{CTA}</a>
                                                )
                                            }
                                        </button>

                                    }
                                </div>
                            </div>
                        )
                    })

                }
                {
                    ERPCOE.map((item, index) => {
                        const { className, HeadingData } = item;
                        return (
                            className === "OurServices" && (
                                <div className={`${className}`} key={index}>
                                    {
                                        HeadingData && (
                                            HeadingData.map((item, index) => {
                                                const { className, Grid, CardSection } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="Heading-Content justify-content-center text-black text-center align-items-center row px-4 padding_remove">
                                                            {
                                                                Grid.map((item, index) => {
                                                                    const { GridName, className, Name } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <p className={className} dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            CardSection && (
                                                                CardSection.map((item, index) => {
                                                                    const { className, CardData } = item;
                                                                    return (
                                                                        <div className={className} key={index}>
                                                                            <div className="card-section-content row px-5 justify-content-center text-center padding_remove">
                                                                                {
                                                                                    CardData.map((item, index) => {
                                                                                        const { GridName, className, ImgName, Heading, Name, UrlName, Url, popUp } = item;
                                                                                        return (

                                                                                            <div className={GridName} key={index} data-aos={`${isSmallScreen ? 'fade-up' : popUp}`}>
                                                                                                <Link to={Url} className='text-decoration-none'>
                                                                                                    <div className={className}>
                                                                                                        <div className="cardImg text-start d-flex justify-content-between align-items-center">
                                                                                                            {
                                                                                                                Heading && (
                                                                                                                    <p className='fw-bolder card-heading-size'>{Heading}</p>
                                                                                                                )
                                                                                                            }
                                                                                                            {ImgName && (
                                                                                                                <img alt="Enterprise ERP Solutions | Phenom&#x1D49;Cloud" className='icon-size icon-box' src={require(`../../image/ERP/ERP COE/${ImgName}`)} />
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className="card-body px-1 d-flex flex-column text-start justify-content-center">
                                                                                                            <p className="Description text-start m-0" dangerouslySetInnerHTML={{ __html: Name }} ></p>
                                                                                                        </div>
                                                                                                        {
                                                                                                            Url && (
                                                                                                                <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                                                                                    <Link to={Url} className='text-start text-decoration-none UrlText px-2' href="">{UrlName}</Link>
                                                                                                                    <Link className='btn card-btn-color' to={Url}><i className="fa-solid fa-arrow-right px-2"></i></Link>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                </Link>
                                                                                            </div>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            )

                                                        }
                                                    </div>
                                                )
                                            })
                                        )
                                    }

                                </div>
                            )
                        )
                    })
                }
                {
                    ERPCOE.map((item, index) => {
                        const { id, Header, Desc, cta_link, CTA } = item;
                        return (
                            id === 8 &&
                            <div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section white_bg py-4`} key={index}>
                                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="Cloud_sloution_cta_header text-center fw-bold ">{Header}</h1>
                                    <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">{Desc}</h3>
                                    {
                                        cta_link &&
                                        <button className="cta_btn">
                                            {
                                                cta_link === '/demo' ? (
                                                    <Link to={cta_link}>{CTA}</Link>
                                                ) : (
                                                    <a href={cta_link} target='_blank'>{CTA}</a>
                                                )
                                            }
                                        </button>

                                    }
                                </div>
                            </div>
                        )
                    })

                }
                {
                    ERPCOE.map((item, index) => {
                        const { id, desc, accordians, title, bannerImage, bottomHeading } = item;
                        return (
                            id === 4 && (

                                <div className="row d-flex justify-content-around mt-3 mb-3" key={index}>
                                    <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                                        <div className=" ">
                                            <div className="container">
                                                <p className="text-center fw-bold" style={{ fontSize: '30px' }} dangerouslySetInnerHTML={{ __html: title }}></p>

                                                {
                                                    desc.map((data, id) => (
                                                        <p className='line-_height text-center' dangerouslySetInnerHTML={{ __html: data.text }}></p>
                                                    ))
                                                }
                                            </div>
                                            <div className="">
                                                <div className="row d-flex justify-content-center whitebg_section edt_digital_transform EB_COE">

                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column  " style={{ padding: '16px' }}>
                                                        <Accordion className='d-flex flex-column EcCoe justify-content-center align-items-center' data-aos={"fade-right"}>
                                                            {accordians.map((item, index) => {
                                                                const { header, desc } = item;
                                                                return (
                                                                    <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }}>
                                                                        <AccordionButton className='Dropdown-Bg-Color'
                                                                            onClick={() => handleToggle(index.toString())}
                                                                            aria-expanded={expandedItem === index.toString()}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                                                <span className='fw-bolder sm-font-size'>{header}</span>
                                                                                {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                            </div>
                                                                        </AccordionButton>

                                                                        <Accordion.Body key={index} className='py-1'>
                                                                            <p className='text-start' dangerouslySetInnerHTML={{ __html: desc }}></p>
                                                                        </Accordion.Body>

                                                                    </Accordion.Item>
                                                                );
                                                            })}
                                                        </Accordion>



                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center EbCardImg">
                                                        <img alt="Enterprise ERP Solutions | Phenom&#x1D49;Cloud" src={require(`../../image/ERP/ERP COE/${bannerImage}.webp`)} className="img-size float-end" data-aos={"fade-left"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-center" style={{ fontSize: '' }} dangerouslySetInnerHTML={{ __html: bottomHeading }}></p>

                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    })
                }

            </div>
            <FooterBrands />
            <GetStarted />
        </>
    )
}
