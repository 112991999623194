import React, { useState, useEffect, useRef } from "react";
import "../style/Contact.css";
import { IoArrowBack } from "react-icons/io5";
import { GetStarted } from "./FooterSection/GetStarted";
import TrustedOrganization from "./TrustedOrganization";
import { Helmet } from 'react-helmet';
import img from '../image/Contact/CounactUsBanner.webp'
import { FaPhone } from "react-icons/fa6";
import { BsPersonVcard } from "react-icons/bs";
import { LuMessagesSquare } from "react-icons/lu";
import Hyderabad from '../image/Contact/HyderabadOffice.jpg'
import ContactUsData from '../data/ContactUS.json'
const Banner = React.lazy(() => import('./Banner'));



const Contact = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [formKey, setFormKey] = useState(0); // State to force re-render
  const MeetingLink = 'https://meetings.hubspot.com/natasha-ramdial-roopnarine';

  const loadForm = () => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "1751195",
          formId: "499be090-f6bb-436f-a61f-00ca7211e520",
          target: `.myFormDemo-${formKey}`, // Use the formKey to differentiate
          onFormSubmit: () => { setSubmitted(true); },
          css: ` .myFormDemo .input input,
                .myFormDemo .input .hs-input {
                  border-radius: 4px !important;
                  border: 1px solid #7691AD;
                  padding: .5rem 1rem !important;
                  width: 100%;
                  line-height: 1.75;
                  transition: all 0.15s ease-out, visibility 0s;
                }

                .myFormDemo .input input::placeholder {
                  opacity: 0;
                }

                .hs-error-msg,
                .hs-main-font-element {
                  color: #cf2738 !important;
                }

                .hs-form .hs-form-field input.invalid {
                  border: 1px solid #cf2738 !important;

                }

                .hs-form {
                  display: flex;
                  flex-direction: column;
                  gap: 1rem !important;
                }

                .actions {
                  display: flex;
                  justify-content: center;
                }

                .hs-form .hs-submit .hs-button {
                  padding: 0.75rem 1.5rem;
                  font-size: 1rem;
                  line-height: 1.75;
                  background: #ff5c35;
                  border: transparent;
                  border-radius: 8px !important;
                  transition: background-color .15s linear, color .15s linear;
                  color: white;
                }
                  .submitted-message{
                    display: none !important;
                  }

                .hs-form .hs-submit .hs-button:hover {
                  background-color: #e04826;
                }
                  @media only screen and (max-width:500px) {
                  .hs-form-field label span{
                    font-size: 12px !important
                  }
                  .input select {
                    font-size: 13px !important
                  }
                }
                  
                `
        });
      }
    });
  };

  useEffect(() => {
    loadForm();
  }, [formKey]); // Load the form whenever the formKey changes

  const CustomPopup = () => {
    return (
      <div className="custom-popup-backdrop">
        <div className="custom-popup">
          <div className='d-flex flex-column justify-content-evenly'>
            <h2 className='text-center fw-bold'>Thank you for reaching out!</h2>
            <p className='text-center text-dark'>
              A Phenom <sup>e</sup>Cloud specialist will get in touch with you soon to discuss how we can help maximize the potential of your HR technology. While you wait, feel free to explore how Phenom eCloud can enhance your HR experience.
            </p>
            <p className='text-center text-dark'>
              We’re excited to work with you!
            </p>
            <p className='text-center d-flex justify-content-center mt-4'>
              <button
                className='btn btn-outline-purple backButton mx-1'
                onClick={() => {
                  setSubmitted(false);
                  setFormKey(prevKey => prevKey + 1); // Increment key to force re-render
                }}>
                <span><IoArrowBack /></span>Back to page
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const [width, setWidth] = useState(window.innerWidth);

  const iframeRef = useRef(null);
  const [iframeWidth, setIframeWidth] = useState(300);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setIframeWidth(entry.contentRect.width); // Set width based on parent/container size
      }
    });

    if (iframeRef.current) {
      resizeObserver.observe(iframeRef.current.parentElement);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (


    <div className="ContantBody">
      <Helmet>
        <title>Phenom&#x1D49;Cloud | Contact Us</title>
        <meta name="description" content=" Get in touch with PhenomeCloud for expert technology consulting, implementation, and support. Let’s collaborate to achieve your business goals." />
      </Helmet>
      {
        ContactUsData.map((item, index) => {
          const { Id, MainClass, Grid, Heading, Desc, Img } = item;
          return (
            <React.Fragment key={index}>
              {
                Id === 1 && (
                  <div className="ContactUSBanner ContactUSBannerBg p-5 d-flex justify-content-center">
                    <div className="row px-5 w-100 mt-5 align-items-center">
                      <div className="col-md-7 py-5 px-5 position-relative">
                        <div className="HeadingSection">
                          <p className="h1">{Heading}</p>
                          <div className="ContactUSBannerDesc">{Desc}</div>
                        </div>
                        <div className="CircleGrapic">
                          <svg aria-hidden="true" width="240" height="224" viewBox="0 0 240 224">
                            <path class="csol-header-photo-orange20" d="M240 120C240 53.7258 186.274 -8.1423e-06 120 -5.24537e-06C53.7258 -2.34843e-06 -8.1423e-06 53.7258 -5.24537e-06 120C-2.34843e-06 186.274 53.7258 240 120 240C186.274 240 240 186.274 240 120Z"></path>
                            <path class="csol-header-photo-tangerine30" d="M210 120C210 70.2944 169.706 29.9999 120 29.9999C70.2942 29.9999 29.9997 70.2944 29.9998 120C29.9998 169.706 70.2943 210 120 210C169.706 210 210 169.706 210 120Z"></path>
                            <path class="csol-header-photo-tangerine40" d="M179.999 120C179.999 86.8633 153.137 60.0006 120 60.0006C86.8632 60.0006 60.0005 86.8633 60.0005 120C60.0005 153.137 86.8632 180 120 180C153.137 180 179.999 153.137 179.999 120Z"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="col-md-5 position-relative">
                        <div className="sideCircleGrapic">
                          <svg aria-hidden="true" width="240" height="224" viewBox="0 0 240 224">
                            <path class="csol-header-photo-orange20" d="M240 120C240 53.7258 186.274 -8.1423e-06 120 -5.24537e-06C53.7258 -2.34843e-06 -8.1423e-06 53.7258 -5.24537e-06 120C-2.34843e-06 186.274 53.7258 240 120 240C186.274 240 240 186.274 240 120Z"></path>
                            <path class="csol-header-photo-tangerine30" d="M210 120C210 70.2944 169.706 29.9999 120 29.9999C70.2942 29.9999 29.9997 70.2944 29.9998 120C29.9998 169.706 70.2943 210 120 210C169.706 210 210 169.706 210 120Z"></path>
                            <path class="csol-header-photo-tangerine40" d="M179.999 120C179.999 86.8633 153.137 60.0006 120 60.0006C86.8632 60.0006 60.0005 86.8633 60.0005 120C60.0005 153.137 86.8632 180 120 180C153.137 180 179.999 153.137 179.999 120Z"></path>
                          </svg>
                        </div>
                        <div className="ContactUsBannerImg">
                          <img src={require(`../image/Contact/${Img}`)} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }

              {
                Id === 2 && (
                  <div className="ContactUsCardSection px-5">
                    <div className="row px-5 justify-content-center">
                      {
                        Grid.map((item, index) => {
                          const { GridName, className, Heading, Desc, Number, href, ariaLabel, BtnName } = item;
                          return (
                            <div className={GridName}>
                              <div className={className}>
                                <div className="Heading py-4 pb-0">
                                  {index === 0 && <FaPhone />}
                                  {index === 1 && <BsPersonVcard />}
                                  {index === 2 && <LuMessagesSquare />}
                                  <p className="my-4 fw-bold HeadingName">{Heading}</p>
                                </div>
                                <div className="Desc pt-2 py-4" >
                                  <p dangerouslySetInnerHTML={{ __html: Desc }}></p>
                                </div>
                                {
                                  (BtnName || Number) && (
                                    <div className="ButtomSection py-3">
                                      {
                                        Number && (
                                          <p> <a href={href} target="_blank" rel="noopener noreferrer" aria-label={ariaLabel}>
                                            {Number}
                                          </a></p>
                                        )
                                      }
                                      {BtnName && (
                                        <a href={href} target="_blank" rel="noopener noreferrer" aria-label={ariaLabel} className="ContactSupportBtn text-decoration-none">{BtnName}</a>
                                      )

                                      }

                                    </div>
                                  )
                                }

                              </div>
                            </div>
                          )
                        })
                      }
                      {/* <div className="col-md-4 my-3">
                        <div className="card p-3 d-flex justify-content-center text-center rounded-0 h-100">
                          <div className="Heading py-4 pb-0">
                            <FaPhone />
                            <p className="my-4 fw-bold HeadingName">Talk to Sales</p>
                          </div>
                          <div className="Desc pt-2 py-4" >
                            <p dangerouslySetInnerHTML={{ __html: Desc }}></p>
                          </div>
                          <div className="ButtomSection py-3">
                            <p><a href="tel:+1 855-978-6816">+1 855-978-6816</a></p>

                          </div>

                        </div>
                      </div> */}

                      {/* <div className="col-md-4 my-3">
                        <div className="card p-3 d-flex justify-content-center text-center rounded-0 h-100">
                          <div className="Heading py-4 pb-0">
                            <BsPersonVcard />
                            <p className="my-4 fw-bold HeadingName">Business Enquiries</p>
                          </div>
                          <div className="Desc pt-2 py-4">
                            <p>If you’re interested in Phenom<sup>e</sup>cloud, reach out to our Managers by calling <a href="tel:+1 855-978-6816">+1 855-978-6816</a>, +91 9553300071, or emailing us at info@phenomecloud.com</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 my-3">
                        <div className="card p-3 d-flex justify-content-center text-center rounded-0 h-100">
                          <div className="Heading py-4 pb-0">
                            <LuMessagesSquare />
                            <p className="my-4 fw-bold HeadingName">Contact Customer Support</p>
                          </div>
                          <div className="Desc pt-2 py-4">
                            <p>Sometimes you need a little help from your friends. Or a Phenom support rep. Don’t worry… we’re here for you.</p>
                          </div>
                          <div className="ButtomSection py-3">
                            <a href="mailto:info@phenomecloud.com" target="_blank" rel="noopener noreferrer" aria-label="Email us at info@phenomecloud.com" className="ContactSupportBtn text-decoration-none">Contact Support</a>
                          </div>

                        </div>
                      </div> */}


                    </div>
                  </div >
                )
              }
              {
                Id === 3 && (
                  <div className="GlobalOffices px-5">
                    <div className="row px-5 justify-content-center">
                      {
                        Grid.map((item, index) => {
                          const { GridName, Heading, Map, Address } = item;
                          return (
                            <div className={GridName} key={index}>
                              {
                                Heading && (
                                  <h3 className="fw-bolder">{Heading}</h3>
                                )
                              }
                              {
                                Map && (
                                  <iframe
                                    ref={iframeRef}
                                    className="rounded-start"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3350.385185006379!2d-96.9731876!3d32.8879828!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c29d6f356b767%3A0xcf9f5c50ae143c9!2s1333%20Corporate%20Dr%20%23212%2C%20Irving%2C%20TX%2075038%2C%20USA!5e0!3m2!1sen!2sin!4v1734620614815!5m2!1sen!2sin"
                                    width={iframeWidth}
                                    height="450"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    title="Google Map"
                                  ></iframe>
                                )
                              }
                              {
                                Address && (
                                  <div className="Headquarters py-5 px-3">
                                    {
                                      Address.map((item, index) => {
                                        const { className, Name, Number, href, ariaLabel } = item;
                                        return (
                                          <React.Fragment key={index}>
                                            {
                                              Name && (
                                                <p {...(className ? { className } : {})}>{Name}</p>
                                              )
                                            }
                                            {
                                              Number && (
                                                <a href={href} target="_blank" rel="noopener noreferrer" aria-label={ariaLabel}>
                                                  {Number}
                                                </a>
                                              )
                                            }
                                          </React.Fragment>
                                        )
                                      })
                                    }

                                  </div>
                                )
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }

              {
                Id === 4 && (
                  <div className="ContactUsFotmSection p-5">
                    <div className=" row p-5 justify-content-center">
                      {
                        Grid.map((item, index) => {
                          const { Heading, GridName } = item;
                          return (
                            <div className={GridName}>
                              {
                                Heading ? (
                                  <h3>{Heading}</h3>
                                ) : (<>
                                  <div className={`myFormDemo myFormDemo-${formKey}`}></div>
                                  {isSubmitted && <CustomPopup />}
                                </>)
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }

              {
                Id === 5 && (
                  <div className="OfficeAddress p-5">
                    <div className="px-5 OfficeAddressConntent">
                      <div className="OfficeAddressMainSetion">
                        {
                          MainClass.map((item, index) => {
                            console.log(index)
                            const { ClassName, GridName, ImgClass, Img, Heading, Address } = item;
                            return (
                              <div className={ClassName}>
                                <div className={GridName}>
                                  {
                                    Img && (
                                      <img src={require(`../image/Contact/${Img}`)} loading="lazy" className={`img-fluid ${ImgClass}`} alt="" />
                                    )
                                  }
                                </div>
                                <div className={GridName}>
                                  <h4 className="my-3">{Heading}</h4>
                                  <div className="Address">
                                    {
                                      Address && (
                                        Address.map((item, index) => {
                                          const { className, Name, Number, href, ariaLabel } = item;
                                          return (
                                            <React.Fragment key={index}>
                                              <h6 className={className}>{Name}</h6>
                                              {
                                                Number && (
                                                  <a href={href} target="_blank" rel="noopener noreferrer" aria-label={ariaLabel}>
                                                    {Number}
                                                  </a>
                                                )
                                              }

                                            </React.Fragment>
                                          )
                                        }))
                                    }
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                )
              }

            </React.Fragment>
          )
        })
      }



    </div >
  );
};

export default Contact;