import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import "./style/NavBar.css";
import "./style/Banner.css";
import "./style/About.css";
import "./style/OurPartners.css";
import "./style/Hcm_Services.css";
import "./style/LearningCOE.css";
import "./style/OurPartnersBarnds.css";
import "./style/agile.css";
import "./style/Home.css";
import "./style/hcmcoe.css";
import "./style/Footer.css";
import "./style/MobileApplication.css";
import "./style/AdvanceAnalytic.css";
import "./style/ERP.css";
import "./style/EC.css";
import "./style/EB.css";
import "./style/CarouselBanner.css";
import "./style/OurClients.css";
import Header from "./components/Header";
import { About } from "./components/About";
// import { OurPartners } from "./components/OurPartners";
// import { LearningCoe } from "./components/LearningCOE/LearningCoe";
// import { OurPartnersBrands } from "./components/OurPartners/OurPartnersBrands";
// JsonData
import AboutUs from "./data/About.json";
import LearningCOE from "./data/LearningCoe/LearningCOE.json";
import Workday from "./data/OurPartnears/Workday.json";
import SalesForce from "./data/OurPartnears/SalesForce.json";
import Sumtotal from "./data/OurPartnears/Sumtotal.json";
import Cornerstone from "./data/OurPartnears/Cornerstone.json";
import Ceridian from "./data/OurPartnears/Ceridian.json";
import Servicenow from "./data/OurPartnears/Servicenow.json";
import serviceData from "./data/HCMLearning/ManagedServices.json";
import ClientData from "./data/OurClients.json";
import DigitalAlliancesData from "./data/DigitalAlliances.json";
import Erp_Coe from "./data/ERP/Erp_Coe.json";
import Ec_Coe from "./data/EC/EC_Coe.json";
import Eb_Coe from "./data/EB/EB_Coe.json";
import cookiePolicy from "./data/CookiePolicy.json";
import SecurityPolicy from "./data/SecurityPolicy.json";
import cornerstonesbxdata from "./data/OurPartnears/CornerstoneSbx.json";
import Phenompressdata from "./data/PressDetails/phenomPress.json";
import CopyRightData from './data/CopyRight.json'
import TechnologyData from './data/Industries/Technology.json'

// import { Home } from "./components/Home/Home";
import HCMCOE from "./components/HCM/HCM_COE";
// import { Footer } from "./components/FooterSection/Footer";
// import { PrivacyPolicy } from "./components/FooterSection/PrivacyPolicy";
import PrivacyPolicyLayout from "./components/PrivacyPolicyLayout";
import Disclaimer from "./components/Disclaimer";
import CookiePolicy from "./components/CookiePolicy";
import SiteMap from "./components/SiteMap";
import ManagedServices from "./components/HCM/Learning/ManagedServices";
import Contact from "./components/Contact";
import Demo from "./components/Demo";
import { ERP_COE } from "./components/ERP/ERP_COE";
import AdvisoryService from "./components/AdvisoryService";
import EDT_COE from "./components/EDT/EDT_COE";
import { EC_COE } from "./components/EC/EC_COE";
// import { EB_COE } from "./components/EB/EB_COE";
// import { TermsandConditions } from "./components/TermsandConditions";
import { PageNotFoundError } from "./components/PageNotFoundError";
import ERPAnalytics from "./components/ERP/ERPAnalytics";
import TechnologyAdvisoryServices from "./components/EB/TechnologyAdvisoryServices";
import Implementation from "./components/ERP/Implementation";
import { Integrations } from "./components/ERP/Integrations";
import { StaffAugumentation } from "./components/ERP/StaffAugumentation";
import { Accelerators } from "./components/ERP/Accelerators";
// import { QualityAssurance } from "./components/Commen/QualityAssurance.jsx";
import { EnterprisePortalServices } from "./components/ERP/EnterprisePortalServices.jsx";
import { RoiCalculator } from "./components/RoiCalculator.jsx";
import DigitalAlliances from "./components/DigitalAlliances.jsx";
// import { OurClients } from "./components/OurClients";
// const Header = React.lazy(() => import('./components/Header'));
import Careers from "./components/TalentHub.jsx";
import TalentHub from "./components/TalentHub.jsx";
import JobDetails from "./components/JobDetails.jsx";
import PressRelease from "./components/PressRelease.jsx";
import PressReleaseDetails from "./components/PressReleaseDetails.jsx";
import Carrer from "./components/Carrer.jsx";
import { LanguageProvider } from "./LanguageContext.js";
import IndustryDetails from "./components/IndustryDetails.jsx";
import Industries from "./components/Industries.jsx";

const Home = React.lazy(() => import('./components/Home/Home'));
const Footer = React.lazy(() => import('./components/FooterSection/Footer'));
const PrivacyPolicy = React.lazy(() => import('./components/FooterSection/PrivacyPolicy'));
const QualityAssurance = React.lazy(() => import('./components/Commen/QualityAssurance'));
const LearningCoe = React.lazy(() => import('./components/LearningCOE/LearningCoe'));
const OurClients = React.lazy(() => import('./components/OurClients'));
const OurPartners = React.lazy(() => import('./components/OurPartners'));
const OurPartnersBrands = React.lazy(() => import('./components/OurPartners/OurPartnersBrands'));
const TermsandConditions = React.lazy(() => import('./components/TermsandConditions'));
const EB_COE = React.lazy(() => import('./components/EB/EB_COE'));


function App() {
  const handleCopy = (event) => {
    event.preventDefault(); // Prevent default copy behavior
    const customText = "Phenom Security Policy";
    navigator.clipboard.writeText(customText)
      .then(() => {
        console.log("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };


  return (
    // onCopy={handleCopy}
    <LanguageProvider>
      <div className="App" >
        <div className="Home_container">
          <Router>
            <Header />
            <Suspense fallback={<></>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/:className" element={<DynamicComponent />}>
                  <Route path=":className" element={<DynamicComponent />} />
                </Route>

                <Route path="/" element={<TalentHub />} />
                <Route path="/TalentHub/job-details/:jobCode" element={<JobDetails />} />
                <Route path="/facebook" element={<Redirect url="https://www.facebook.com/phenomecloud/" />} />
                <Route path="/instagram" element={<Redirect url="https://www.instagram.com/phenomecloud/" />} />
                <Route path="/youtube" element={<Redirect url="https://www.youtube.com/@phenomecloud/" />} />
                <Route path="/linkdin" element={<Redirect url="https://www.linkedin.com/company/phenomecloud/" />} />
                <Route path="/twitter" element={<Redirect url="https://x.com/phenomecloud/" />} />


                {/* <Route path="/:className" element={<DynamicComponent />} /> */}
                <Route path="*" element={<PageNotFoundError />} />
              </Routes>
              <Footer />
              <PrivacyPolicy />
            </Suspense>
          </Router>
        </div>
      </div>
    </LanguageProvider>
  );
}
const Redirect = ({ url }) => {
  window.location.href = url;
  return null; // The component returns nothing because it's handling the redirection
};

const validRoutes = [
  "enterprise-hrtech-solutions",
  "enterprise-integrations",
  "application-managed-services",
  "enterprise-erp-solutions",
  "enterprise-digital-solutions",
  "enterprise-cloud-solutions",
  "enterprise-business-solutions",
  "enterprise-learning-solutions",
  "workday",
  "salesforce",
  "sumtotal",
  "cornerstone",
  "ceridian",
  "servicenow",
  "CornerstoneSbx",
  "aboutUs",
  "ourPartners",
  "PrivacyPolicy",
  "disclaimer",
  "cookiePolicy",
  "securitypolicy",
  "site-map",
  "enterprise-technology-advisory",
  "terms&conditions",
  "contact",
  "demo",
  "enterprise-analytics-services",
  "enterprise-technology-integrations",
  "enterprise-it-staffing-services",
  "enterprise-portal-services",
  "enterprise-accelerators",
  "enterprise-technology-advisory",
  "enterprise-technology-implementations",
  "enterprise-quality-assurance-services",
  "OurClient",
  "DigitalAlliances",
  "roi-calculator",
  'TalentHub',
  'Press',
  'PressReleaseDetails',
  'Carrer',
  'Industries',
  'CopyRight',
  'IndustryDetails',
  'technology'

];

const DynamicComponent = () => {
  const { className } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [className]);

  // Check if className is valid
  if (!validRoutes.includes(className)) {
    return <PageNotFoundError />;
  }

  switch (className) {
    // --------------------------------
    case "enterprise-hrtech-solutions":
      return <HCMCOE />;
    case "application-managed-services":
      return <ManagedServices adata={serviceData} />;
    case "enterprise-erp-solutions":
      return <ERP_COE adata={Erp_Coe} />;
    case "enterprise-digital-solutions":
      return <EDT_COE />;
    case "enterprise-cloud-solutions":
      return <EC_COE adata={Ec_Coe} />;
    case "enterprise-business-solutions":
      return <EB_COE adata={Eb_Coe} />
    case "enterprise-learning-solutions":
      return <LearningCoe adata={LearningCOE} />;
    case "workday":
      return <OurPartnersBrands adata={Workday} />;
    case "salesforce":
      return <OurPartnersBrands adata={SalesForce} />;
    case "sumtotal":
      return <OurPartnersBrands adata={Sumtotal} />;
    case "cornerstone":
      return <OurPartnersBrands adata={Cornerstone} />;
    case "ceridian":
      return <OurPartnersBrands adata={Ceridian} />;
    case "servicenow":
      return <OurPartnersBrands adata={Servicenow} />;
    case "CornerstoneSbx":
      return <OurPartnersBrands adata={cornerstonesbxdata} />;
    case "aboutUs":
      return <About adata={AboutUs} />;
    case "ourPartners":
      return <OurPartners />;
    case "PrivacyPolicy":
      return <PrivacyPolicyLayout />;
    case "disclaimer":
      return <Disclaimer />;
    case "cookiePolicy":
      return <CookiePolicy adata={cookiePolicy} />;
    case "CopyRight":
      return <CookiePolicy adata={CopyRightData} />;
    case "securitypolicy":
      return <CookiePolicy adata={SecurityPolicy} />;
    case "site-map":
      return <SiteMap />;
    case "enterprise-technology-advisory":
      return <AdvisoryService />;
    case "terms&conditions":
      return <TermsandConditions />;
    case "contact":
      return <Contact />;
    case "demo":
      return <Demo />;
    case "enterprise-analytics-services":
      return <ERPAnalytics />;
    case "enterprise-technology-integrations":
      return <Integrations />;
    case "enterprise-it-staffing-services":
      return <StaffAugumentation />;
    case "enterprise-portal-services":
      return <EnterprisePortalServices />;
    case "enterprise-accelerators":
      return <Accelerators />;
    case "enterprise-technology-advisory":
      return <TechnologyAdvisoryServices />;
    case "enterprise-technology-implementations":
      return <Implementation />;
    case "enterprise-quality-assurance-services":
      return <QualityAssurance />;
    case "OurClient":
      return <OurClients adata={ClientData} />;
    case "DigitalAlliances":
      return <DigitalAlliances adata={DigitalAlliancesData} />;
    case "roi-calculator":
      return <RoiCalculator />;
    case "TalentHub":
      return <TalentHub />;
    case "Carrer":
      return <Carrer />;
    case "Industries":
      return <Industries />;
    case "technology":
      return <IndustryDetails adata={TechnologyData} />;
    case "Press":
      return <PressRelease />;
    case "PressReleaseDetails":
      return <PressReleaseDetails adata={Phenompressdata} />;
    // default:
    //   return <PageNotFound />;
    default:
      return <PageNotFoundError />;
  }
};

export default App;
