import React, { useState, useEffect } from "react";
import "../style/Demo.css";
import { IoArrowBack } from "react-icons/io5";
import { GetStarted } from "./FooterSection/GetStarted";
import TrustedOrganization from "./TrustedOrganization";
import { Helmet } from 'react-helmet';
import { WidthFull } from "@mui/icons-material";
import bannerData from '../data/Demo.json';
const Banner = React.lazy(() => import('./Banner'));



const Contact = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [formKey, setFormKey] = useState(0); // State to force re-render
  const MeetingLink = 'https://meetings.hubspot.com/natasha-ramdial-roopnarine';

  const loadForm = () => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "1751195",
          formId: "a74585cb-3938-4132-b8ec-da98d81e2747",
          target: `.myFormDemo-${formKey}`, // Use the formKey to differentiate
          onFormSubmit: () => { setSubmitted(true); },
        });
      }
    });
  };

  useEffect(() => {
    loadForm();
  }, [formKey]);

  const CustomPopup = () => {
    return (
      <div className="custom-popup-backdrop">
        <div className="custom-popup">
          <div className='d-flex flex-column justify-content-evenly'>
            <h2 className='text-center fw-bold'>Thank you for reaching out!</h2>
            <p className='text-center text-dark'>
              A Phenom<sup>e</sup>Cloud specialist will get in touch with you soon to discuss how we can help maximize the potential of your HR technology. While you wait, feel free to explore how Phenom<sup>e</sup>Cloud can enhance your HR experience.
            </p>
            <p className='text-center text-dark'>
              We’re excited to work with you!
            </p>
            <p className='text-center d-flex justify-content-center mt-4'>
              <button
                className='btn btn-outline-purple backButton mx-1'
                onClick={() => {
                  setSubmitted(false);
                  setFormKey(prevKey => prevKey + 1); // Increment key to force re-render
                }}>
                <span><IoArrowBack /></span>Back to page
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="ContantBody" style={{ paddingTop: '2%' }}>
      {/* <Helmet>
        <title> Phenom&#x1D49;Cloud | Demo</title>
        <meta name="description" content="Discover how PhenomeCloud’s experts deliver strategic consulting, implementation, maintenance, and optimization for human capital management technology like Ceridian Dayforce®." />
      </Helmet>

      <Banner /> */}
      {/* <TrustedOrganization /> */}
      {/* <div className='bg-color-form text-dark pt-4'>
        <div className="contact-form row container-fluid justify-content-center   p-0 w-100">
          <div className='demo d-flex justify-content-center text-dark form_section col-10 col-md-8 p-3'>
            <div className='mt-4 demo form_section_content w-100 pt-3' >
              <h2 className='fw-bold text-center'>Schedule a Demo</h2>
              <div className={`myFormDemo myFormDemo-${formKey}`}></div>
              {isSubmitted && <CustomPopup />}
            </div>
          </div>
        </div>
      </div>
      <GetStarted /> */}
      <div className="px-5 Banner_main_container container-fluid justify-content-center w-100">

        <div className="px-4 bannerContainer h-100">
          <div className="col-md-6 text-left ps-3">
            <h1 className="my-2">{bannerData.bannerSection.header}</h1>
            <p>{bannerData.bannerSection.subHeader}</p>
          </div>
        </div>


        <div className="cardsContainer h-100 d-flex justify-content-between align-items-center flex-wrap mx-auto" style={{ width: '86%', marginBottom: '2rem' }}>
          {bannerData.cards.map((card, index) => (
            <div className="subcontainer col-lg-3 col-md-12" key={index}>
              <img src={card.image} alt={card.heading} />
              <h5 className="containerHeading">{card.heading}</h5>
              {card.contactLink ? (
                <a href={card.contactLink} className="my-1 ContactNumberdemo small fw-normal text-decoration-none" target="_blank" rel="noopener noreferrer">
                  {card.contactNumber}
                </a>
              ) : (
                <a href={card.buttonLink}>
                  <button className="btn" style={{ color: "#fff", backgroundColor: "#EF5713", borderRadius: '10px' }}>
                    {card.buttonLabel}
                  </button>
                </a>
              )}
            </div>
          ))}
        </div>


        <div id="chatWithUs" style={{ width: '100%' }}>
          <h1 className="text-center fw-bold">{bannerData.chatSection.header}</h1>
          <div style={{ width: '100%' }}>
            <iframe
              title={bannerData.chatSection.iframeSrc}
              className="mx-auto d-flex iframecontent"
              alt="demo meeting link"
              src={bannerData.chatSection.iframeSrc}
              style={{ width: '100%', height: '870px' }}
            ></iframe>
          </div>
        </div>


        <div id="demoform" style={{ width: '100%' }}>
          <div className="d-flex justify-content-center mx-auto p-0 w-100">
            <div className="demo d-flex justify-content-center text-dark form_section col-12 col-md-9 p-3">
              <div className="mt-4 demo form_section_content w-100 pt-3">
                <div className="text-center" style={{ marginBottom: '2%' }}>
                  <h3>{bannerData.demoSection.header}</h3>
                  <p>{bannerData.demoSection.subHeader}</p>
                </div>
                <div className={`myFormDemo myFormDemo-${formKey}`}></div>
                {isSubmitted && <CustomPopup />}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Contact;